import {
  Center,
  Checkbox,
  Divider,
  Flex,
  HStack,
  useBreakpointValue
} from "@chakra-ui/react";
import { useLocale } from "app/locale";
import { Button, GearIcon, MinusIcon, PlusIcon } from "components/core";
import { AIIcon } from "components/image-viewer/plugins/icons";

export const BatchProcessingButtons = ({
  checkBox,
  images,
  checkedImages,
  isPending,
  onCheckBoxAll,
  startAIAnalysis,
  updateAllOrganModel
}: {
  checkBox: string[];
  checkedImages: Medmain.Image[];
  images: Medmain.Image[];
  isPending: boolean;
  onCheckBoxAll: () => void;
  startAIAnalysis: () => void;
  updateAllOrganModel: () => Promise<void>;
}) => {
  const locale = useLocale();
  const device = useBreakpointValue({
    base: "pc",
    sm: "sp",
    md: "tablet",
    pc: "pc"
  });

  return (
    <Flex>
      <Checkbox
        colorScheme="primary"
        onChange={onCheckBoxAll}
        isChecked={checkBox.length > 0}
        icon={<CheckBoxIcon checkBox={checkBox} images={images} />}
        ml={2.5}
        isDisabled={images.length === 0}
      />
      <HStack spacing={{ base: 1.5, md: 3 }}>
        <Button
          primary
          onClick={startAIAnalysis}
          leftIcon={<AIIcon size={24} />}
          isLoading={isPending}
          isDisabled={!checkedImages.length}
          ml={{ base: 2.5, md: 6 }}
        >
          {locale.todo(
            `${device === "sp" ? "AI Prediction" : "Start AI analysis"}`
          )}
        </Button>
        {updateAllOrganModel && (
          <>
            {device !== "sp" && (
              <Center height="35px">
                <Divider orientation="vertical" />
              </Center>
            )}
            <Button
              onClick={updateAllOrganModel}
              leftIcon={<GearIcon fontSize={16} color="primary.500" />}
              isDisabled={!checkBox.length}
              color="primary.500"
              borderColor="primary.500"
            >
              {locale.todo("Set AI Model")}
            </Button>
          </>
        )}
      </HStack>
    </Flex>
  );
};

const CheckBoxIcon = ({ checkBox, images }) => {
  return checkBox.length === images.length ? <PlusIcon /> : <MinusIcon />;
};
