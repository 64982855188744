import { sortStringWithNumber } from "../../utils/sort";

// Sort images taking into account numbers that may be included in the filename
export function sortCaseImages(
  pathologicCase: Medmain.Case,
  caseSorts?: Medmain.CaseSort[]
): Medmain.Image[] {
  if (caseSorts && caseSorts.length > 0) {
    return sortStringWithNumber(pathologicCase.images, caseSorts);
  }
  return sortStringWithNumber(pathologicCase.images, [
    { field: "displayName", order: "ASC" }
  ]);
}

// Image filenames are supposed to be made of a case number and a slide number separated by `--`.
// E.g. `2021-533--2.svs`
// The thumbnail cards show the slide number as the title of the image, when available.
export function extractImageTitle(
  image: Medmain.Image,
  caseNumber: Medmain.Case["caseNumber"]
): string {
  const removeExtension = filename => {
    const index = filename.lastIndexOf(".");
    return index === -1 ? filename : filename.slice(0, index);
  };

  const removeCaseNumber = filename => {
    const separator = "--";
    const prefix = `${caseNumber}${separator}`;
    if (prefix === filename) {
      // Edge case: a separator is provided but nothing after!  `<case number>--`
      return caseNumber;
    }
    const index = filename.indexOf(prefix);
    return index === -1 ? filename : filename.slice(index + prefix.length);
  };

  return removeCaseNumber(removeExtension(image.filename));
}
