import { AiFillFile } from "react-icons/ai";
import { MdComment, MdPictureAsPdf } from "react-icons/md";
import { BsPencil } from "react-icons/bs";
import { TiImage } from "react-icons/ti";
import { Icon, IconProps } from "@chakra-ui/react";

import { isAttachmentsExcludeImage, isPDF } from "utils/files";

export const FileIcon = ({
  filename,
  ...props
}: { filename: string } & IconProps) => {
  const IconComponent = getFileIcon(filename);
  return <IconComponent {...props} />;
};

export const FileIconImage = (props: IconProps) => (
  <Icon as={TiImage} {...props} />
);

export const AssetIconImage = (props: IconProps) => (
  <Icon as={AiFillFile} {...props} />
);

export const FileIconPDF = (props: IconProps) => (
  <Icon as={MdPictureAsPdf} {...props} />
);

export const CommentIcon = (props: IconProps) => (
  <Icon as={MdComment} {...props} />
);

export const AnnotationIcon = (props: IconProps) => (
  <Icon as={BsPencil} {...props} />
);

export function getFileIcon(
  filename: string
): (props: IconProps) => JSX.Element {
  return isPDF(filename)
    ? FileIconPDF
    : isAttachmentsExcludeImage(filename)
    ? AssetIconImage
    : FileIconImage;
}
