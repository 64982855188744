import {
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  IconProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Stack,
  StyleProps,
  Text,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  AiOutlineQuestionCircle,
  AiOutlineStar,
  AiTwotoneStar
} from "react-icons/ai";
import { FiPlusSquare } from "react-icons/fi";
import { HiOutlineFilter } from "react-icons/hi";

import { useLocale } from "app/locale";

const AlertIcon = (props: IconProps) => (
  <Icon viewBox="0 0 19.308 17.013" width="19.308" height="17.013" {...props}>
    <g transform="translate(-1.935 -3.844)" fill="currentColor">
      <path
        d="M20.114,20.856H3.065a1.136,1.136,0,0,1-.976-.562,1.119,1.119,0,0,1,0-1.128L10.615,4.407a1.126,1.126,0,0,1,1.95,0L21.071,19.13a1.128,1.128,0,0,1-.957,1.726ZM11.56,4.948h-.009L3.023,19.705l.042.07H20.113a.05.05,0,0,0,.047-.046c0-.006-.023-.052-.026-.058L11.628,4.948Z"
        transform="translate(0)"
      />
      <path
        d="M17.126,13.585V18.29a.614.614,0,0,0,1.228,0V13.585a.614.614,0,1,0-1.228,0Zm0,0"
        transform="translate(-6.063 -3.642)"
      />
      <path
        d="M17.74,24.209a.614.614,0,0,0-.614.612v.412a.614.614,0,0,0,1.228,0v-.412A.614.614,0,0,0,17.74,24.209Zm0,0"
        transform="translate(-6.063 -8.128)"
      />
    </g>
  </Icon>
);

const BadgeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 14.831 19.604" width="14.831" height="19.604" {...props}>
    <path
      d="M17.718,27l.151,3.064-3.1-2.154-3.116,2.155L11.8,27H10.5l-.225,4.575h1.5l3-2.074,2.985,2.074h1.5L19.027,27Z"
      transform="translate(-7.345 -11.971)"
      fill="currentColor"
    />
    <path
      d="M19.163,6.8l-.089-1.963L17.419,3.781,16.359,2.125,14.4,2.035l-1.745-.9-1.745.9-1.963.09L7.885,3.781,6.229,4.84,6.14,6.8l-.9,1.745.9,1.745.09,1.963,1.655,1.059L8.944,14.97l1.963.089,1.745.9,1.745-.9,1.963-.089,1.059-1.655,1.655-1.059.089-1.963.9-1.745ZM17.871,9.946,17.8,11.519l-1.326.849-.849,1.326-1.573.072-1.4.724-1.4-.724L9.68,13.695l-.849-1.326L7.5,11.519,7.433,9.946l-.724-1.4.724-1.4L7.5,5.576l1.326-.849L9.68,3.4l1.573-.072,1.4-.724,1.4.724,1.573.072.849,1.326,1.326.849.072,1.573.724,1.4Z"
      transform="translate(-5.236 -1.132)"
      fill="currentColor"
    />
  </Icon>
);

const CheckCircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 17.794 17.794" width="17.794" height="17.794" {...props}>
    <path
      d="M8.9,16.681A7.785,7.785,0,1,0,1.112,8.9,7.785,7.785,0,0,0,8.9,16.681Zm0,1.112A8.9,8.9,0,1,0,0,8.9,8.9,8.9,0,0,0,8.9,17.794Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M16.844,10.926a.834.834,0,1,1,1.191,1.168L13.6,17.643a.834.834,0,0,1-1.2.022L9.453,14.723a.834.834,0,1,1,1.179-1.179l2.329,2.327,3.862-4.921a.263.263,0,0,1,.022-.024Z"
      transform="translate(-4.644 -5.399)"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Icon>
);

const CheckDottedCircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 18 18" width="18" height="18" {...props}>
    <g transform="translate(-0.534 0.216)" fill="currentColor">
      <path
        d="M16.844,10.926a.834.834,0,1,1,1.191,1.168L13.6,17.643a.834.834,0,0,1-1.2.022L9.453,14.723a.834.834,0,1,1,1.179-1.179l2.329,2.327,3.862-4.921a.263.263,0,0,1,.022-.024Z"
        transform="translate(-3.648 -5.399)"
        fillRule="evenodd"
      />
      <g
        transform="translate(0.534 -0.216)"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeDasharray="2 2"
      >
        <circle cx="9" cy="9" r="9" stroke="none" />
        <circle cx="9" cy="9" r="8.5" fill="none" />
      </g>
    </g>
  </Icon>
);

const MicroscopeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 13.697 15.98" width="13.697" height="15.98" {...props}>
    <path
      fill="currentColor"
      d="M16.709,14.805a4.5,4.5,0,0,0-.953-4.89A2.236,2.236,0,0,0,15.137,7.4l1.584-1.584a1.142,1.142,0,0,0,0-1.615L15.107,2.583a1.142,1.142,0,0,0-1.615,0L5.976,10.1a1.143,1.143,0,0,0,0,1.615l1.614,1.614a1.142,1.142,0,0,0,1.615,0L11.929,10.6a2.245,2.245,0,0,0,3.146.247,3.392,3.392,0,0,1,.369,3.954h-4.1v2.283H4.5v1.141H18.2V14.805ZM8.4,12.522,6.783,10.908,7.855,9.836l1.614,1.614ZM11.348,9.1a2.272,2.272,0,0,0,.043.429l-1.116,1.116L8.662,9.029,14.3,3.391,15.914,5,14.06,6.859A2.27,2.27,0,0,0,11.348,9.1Zm2.283,1.141A1.141,1.141,0,1,1,14.773,9.1,1.141,1.141,0,0,1,13.631,10.239Zm3.424,6.848H12.49V15.946h4.566Z"
      transform="translate(-4.5 -2.249)"
    />
  </Icon>
);

const StethoscopeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 13.33 15.552" width="13.33" height="15.552" {...props}>
    <path
      fill="currentColor"
      d="M15.608,2.25V3.361h1.111V6.693a2.222,2.222,0,1,1-4.443,0V3.361h1.111V2.25H11.165V6.693a3.336,3.336,0,0,0,2.777,3.283v3.382a3.333,3.333,0,1,1-6.665,0V9.947a2.222,2.222,0,1,0-1.111,0v3.412a4.443,4.443,0,1,0,8.887,0V9.976A3.336,3.336,0,0,0,17.83,6.693V2.25ZM5.611,7.8A1.111,1.111,0,1,1,6.722,8.915,1.111,1.111,0,0,1,5.611,7.8Z"
      transform="translate(-4.5 -2.25)"
    />
  </Icon>
);

const PUBLIC_LABELLED_ICONS = [
  {
    name: "MicroscopeIcon",
    Icon: MicroscopeIcon
  },
  {
    name: "StethoscopeIcon",
    Icon: StethoscopeIcon
  },
  {
    name: "AlertIcon",
    Icon: AlertIcon
  },
  {
    name: "CheckCircleIcon",
    Icon: CheckCircleIcon
  },
  {
    name: "CheckDottedCircleIcon",
    Icon: CheckDottedCircleIcon
  },
  {
    name: "BadgeIcon",
    Icon: BadgeIcon
  }
];

export const TwotoneStarIcon = styled(AiTwotoneStar)`
  path:nth-of-type(1) {
    color: #fad60d;
  }
  path:nth-of-type(2) {
    color: #edcb0e;
  }
`;

export const ToggledPrivateLabelledIcon = ({
  privateLabelled,
  size,
  isDisabled,
  ...props
}: {
  privateLabelled: boolean;
  size: string | number;
  isDisabled: boolean;
} & IconProps) => {
  const theme = useTheme();
  return (
    <IconButton
      aria-label="toggle-private-label"
      variant="unstyled"
      _focus={{ outline: "none" }}
      isDisabled={isDisabled}
      _disabled={{ border: 0 }}
      size={size.toString()}
      icon={
        <Icon
          as={privateLabelled ? TwotoneStarIcon : AiOutlineStar}
          color={privateLabelled ? "#edcb0e" : theme.colors.gray["250"]}
          w={size}
          h={size}
          {...props}
        />
      }
    />
  );
};

type Status<T> = ({ name, color }: { name: string; color: string }) => T;
type Props = {
  onClick: Status<void>;
  isActive: Status<boolean>;
  isDisabled?: boolean;
  isFilter?: boolean;
  hasLabelleds?: boolean;
};
const AddStatusPopover = ({
  onClick,
  isActive,
  isDisabled,
  isFilter,
  hasLabelleds
}: Props) => {
  const locale = useLocale();
  const theme = useTheme();
  return (
    <Popover arrowShadowColor="#707070">
      <PopoverTrigger>
        <Center>
          <IconButton
            variant="unstyled"
            size="16px"
            height="16px"
            icon={
              isFilter ? (
                hasLabelleds ? (
                  <HiOutlineFilter size={16} />
                ) : (
                  <HiOutlineFilter size={16} color={theme.colors.gray["250"]} />
                )
              ) : (
                <FiPlusSquare size={16} color={theme.colors.gray["250"]} />
              )
            }
            aria-label="add-public-labelled"
            _focus={{ border: "none" }}
          />
        </Center>
      </PopoverTrigger>
      <PopoverContent
        width="auto"
        borderColor="#707070"
        _focus={{ borderColor: "#707070" }}
      >
        <PopoverArrow />
        <PopoverBody p={11} pb={0}>
          <Flex flexDirection="column">
            {["#000000", "#ac1111", "#dbb900", "#1565a0"].map((color, i) => (
              <Flex key={i}>
                {PUBLIC_LABELLED_ICONS.map((Element, j) => (
                  <IconButton
                    key={j}
                    onClick={() => onClick({ name: Element.name, color })}
                    icon={<Element.Icon key={j} color={color} />}
                    aria-label="public-labelled"
                    variant="ghost"
                    bg={
                      isActive({ name: Element.name, color })
                        ? theme.colors.gray["100"]
                        : "transparent"
                    }
                    isDisabled={isDisabled}
                    _disabled={{ border: 0 }}
                    _hover={{ bg: theme.colors.gray["100"] }}
                    _focus={{ boxShadow: "none" }}
                  />
                ))}
              </Flex>
            ))}
          </Flex>
        </PopoverBody>
        <PopoverFooter border={0} pt={0}>
          <Center>
            <Stack isInline>
              <Icon as={AiOutlineQuestionCircle} alignSelf="center" />
              <Text align="center">
                {locale.todo("Press selected to deselect")}
              </Text>
            </Stack>
          </Center>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export const StatusIcons = ({
  publicLabelleds,
  onClick,
  isActive,
  isDisabled,
  isFilter,
  canAddIcon,
  iconSize = "20px",
  ...props
}: {
  publicLabelleds?: (
    | Medmain.Labelled
    | { caseId?: Medmain.Case["id"]; name: string; color: string }
  )[];
  canAddIcon: boolean;
  iconSize?: React.ComponentProps<typeof Icon>["boxSize"];
} & Props &
  StyleProps) => {
  return !canAddIcon && (!publicLabelleds || publicLabelleds.length === 0) ? (
    <></>
  ) : (
    <Wrap spacing={2} align="center" {...props}>
      {publicLabelleds?.map((checkedIcon, index) => (
        <WrapItem key={index}>
          <Icon
            as={
              PUBLIC_LABELLED_ICONS.find(Element =>
                "labelName" in checkedIcon
                  ? Element.name === checkedIcon.labelName.split("-")[0]
                  : Element.name === checkedIcon.name
              )?.Icon
            }
            color={
              "labelName" in checkedIcon
                ? checkedIcon.labelName.split("-")[1]
                : checkedIcon.color
            }
            boxSize={iconSize}
          />
        </WrapItem>
      ))}
      {canAddIcon && (
        <WrapItem>
          <AddStatusPopover
            onClick={onClick}
            isActive={isActive}
            isDisabled={isDisabled}
            isFilter={isFilter}
            hasLabelleds={publicLabelleds && publicLabelleds.length > 0}
          />
        </WrapItem>
      )}
    </Wrap>
  );
};

export const TableStatusIcons = (
  props: React.ComponentProps<typeof StatusIcons>
) => {
  const {
    publicLabelleds,
    onClick,
    isActive,
    isDisabled,
    isFilter,
    canAddIcon,
    ...styleProps
  } = props;

  return (
    <Flex {...styleProps} align="center" justify="left">
      {canAddIcon && (
        <Box>
          <AddStatusPopover
            onClick={onClick}
            isActive={isActive}
            isDisabled={isDisabled}
            isFilter={isFilter}
            hasLabelleds={publicLabelleds && publicLabelleds.length > 0}
          />
        </Box>
      )}
      <Wrap ml={3} w="80px">
        {publicLabelleds?.map((checkedIcon, index) => (
          <WrapItem key={index}>
            <Icon
              as={
                PUBLIC_LABELLED_ICONS.find(Element =>
                  "labelName" in checkedIcon
                    ? Element.name === checkedIcon.labelName.split("-")[0]
                    : Element.name === checkedIcon.name
                )?.Icon
              }
              color={
                "labelName" in checkedIcon
                  ? checkedIcon.labelName.split("-")[1]
                  : checkedIcon.color
              }
              width="20px"
              height="20px"
            />
          </WrapItem>
        ))}
      </Wrap>
    </Flex>
  );
};
