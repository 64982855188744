import React from "react";

const WIDTH = 700;
const HEIGHT = 147.08;
const RATIO = HEIGHT / WIDTH;

type Props = {
  id: string;
  width: number;
};
export class PidPortLogo extends React.Component<Props> {
  static defaultProps = {
    width: 200
  };

  render() {
    const { id, width } = this.props;

    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width + "px"}
        height={width * RATIO + "px"}
        viewBox="0 0 700 147.08"
      >
        <defs>
          <linearGradient
            id={`${id}-ref0`}
            x1="62.01"
            y1="105.62"
            x2="87.57"
            y2="80.05"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#c80096" />
            <stop offset="0.21" stopColor="#ad0ca1" />
            <stop offset="0.56" stopColor="#851eb1" />
            <stop offset="0.83" stopColor="#6d29ba" />
            <stop offset="1" stopColor="#642dbe" />
          </linearGradient>
          <linearGradient
            id={`${id}-ref2`}
            x1="36.86"
            y1="130.77"
            x2="113.14"
            y2="54.48"
            href={`#${id}-ref0`}
          />
          <linearGradient
            id={`${id}-ref3`}
            x1="29.71"
            y1="104.12"
            x2="120.14"
            y2="13.69"
            href={`#${id}-ref0`}
          />
          <linearGradient
            id={`${id}-ref1`}
            x1="293.86"
            y1="231.02"
            x2="580.38"
            y2="-55.49"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#c80096" />
            <stop offset="0.03" stopColor="#c10399" />
            <stop offset="0.23" stopColor="#9915a9" />
            <stop offset="0.4" stopColor="#7c22b4" />
            <stop offset="0.56" stopColor="#6a2abc" />
            <stop offset="0.67" stopColor="#642dbe" />
            <stop offset="1" stopColor="#850090" />
          </linearGradient>
        </defs>
        <g>
          <g>
            <circle
              style={{ fill: `url(#${id}-ref0)` }}
              cx="74.79"
              cy="92.84"
              r="18.08"
            />
            <path
              style={{ fill: `url(#${id}-ref2)` }}
              d="M31.19,125.09a54.28,54.28,0,1,1,13.94,13.15l5.78-21.55a33.77,33.77,0,1,0-8.63-32.81Z"
            />
            <path
              style={{ fill: `url(#${id}-ref3)` }}
              d="M149.58,74.41A78.92,78.92,0,0,0,0,74.41H0A74.57,74.57,0,0,1,74.79,0c42.8,0,74.79,33.29,74.79,74.41Z"
            />
            <path
              style={{ fill: `url(#${id}-ref1)` }}
              d="M194.27,131.43V36.87h38.6c24.58,0,35.57,13.59,35.57,32.1s-11,32.09-35.57,32.09h-20.1v30.37ZM233.16,84c12.29,0,16.48-6.36,16.48-15s-4.19-14.9-16.48-14.9H212.77V84Zm46.63-43.95a11.42,11.42,0,1,1,22.84,0,11.42,11.42,0,0,1-22.84,0Zm2.89,91.38V60h17.2v71.43ZM390,95.14c0,23.13-15.62,38.17-38.17,38.17s-38.46-15.91-38.46-37.6c0-25.3,18.21-37.59,35.28-37.59,10.7,0,19.23,4.63,23.85,11.86h.29V28.77H390Zm-17.21.57c0-12.57-8.38-21.54-21-21.54s-21.11,9-21.11,21.54,8.53,21.55,21.11,21.55S372.75,108.29,372.75,95.71Zm34.91,35.79V36.94h40.92c22.84,0,33,12.72,33,30.07s-10.26,30.22-33,30.22H418.07V131.5Zm41.06-43.81c16.34,0,22.27-9,22.27-20.68s-5.93-20.53-22.27-20.53H418.07V87.69Zm77.78,45.69C504,133.38,488,117.48,488,95.79S504,58.19,526.5,58.19,565,74.1,565,95.79,549.06,133.38,526.5,133.38Zm28.63-37.59c0-16.48-11.56-28.63-28.63-28.63s-28.62,12.15-28.62,28.63,11.56,28.63,28.62,28.63S555.13,112.27,555.13,95.79ZM625,89.72v-1c0-14.89-8.24-21.54-20.38-21.54-12.29,0-20.39,6.65-20.39,21.54v42.8H574.5V88.7c0-20.53,12.29-30.51,30.08-30.51s29.93,10,29.93,30.66v.87Zm75,15.47c0,18.79-11.28,28.19-27.76,28.19s-28-9.4-28-28.19V34.92h9.69V60.08H696v8.81H653.88v36.3c0,13,7.22,19.23,18.36,19.23s18.36-6.08,18.36-19.23V103.6H700Z"
            />
          </g>
        </g>
      </svg>
    );
  }
}
