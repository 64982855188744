import React from "react";
import {
  Box,
  BoxProps,
  Divider,
  Flex,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  Stack,
  useBreakpoint
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { IconType } from "react-icons";
import { AiOutlineFileSearch } from "react-icons/ai";
import { CgFileDocument } from "react-icons/cg";
import { FaRegBuilding } from "react-icons/fa";
import { FiUsers, FiX } from "react-icons/fi";
import {
  RiFileDownloadLine,
  RiFileUploadLine,
  RiFolderUploadLine
} from "react-icons/ri";
import {
  Link as RouterLink,
  LinkProps,
  NavLink,
  useHistory
} from "react-router-dom";
import { useLocale } from "app/locale";
import { useAccount } from "app/auth-container";
import { BulkUploadContainer } from "app/upload-bulk-images-container";
import { ImportCSVContainer } from "app/import-csv-container";
import { PidPortLogo } from "./pidport-logo";
import { UserMenu } from "./user-menu";
import { Footer } from "./footer";
import { HeadHelpLink } from "./header";

export function Sidebar({
  isOpen,
  width,
  onClose
}: {
  isOpen: boolean;
  width: number | string;
  onClose: () => void;
}) {
  const screenSize = useBreakpoint() ?? "";

  React.useEffect(() => {
    let className = "body-lock";

    if (isOpen) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  }, [isOpen]);

  return (
    <Flex
      backgroundColor="#F3F7FA"
      flexDirection="column"
      borderRightWidth="1px"
      width={width}
      height="100%"
      pt={{ base: 11, lg: 0 }}
      pb={{ base: 30, lg: 0 }}
      position={{ base: "fixed" }}
      zIndex={{ base: 20 }}
      transform={{
        base: `translateY(${isOpen ? 0 : "-100%"})`,
        lg: "initial"
      }}
      transition={{ base: "transform 0.5s", lg: "none" }}
    >
      <Stack
        spacing={0}
        flexGrow={{ base: "initial", lg: 1 }}
        shouldWrapChildren
      >
        <Flex justifyContent="space-between" pr={{ base: 6, lg: 0 }}>
          <Logo />
          <Flex>
            {["sm", "md"].includes(screenSize) && (
              <>
                <HeadHelpLink />
                <UserMenu />
              </>
            )}
            <IconButton
              display={{ base: "block", lg: "none" }}
              ml={0}
              mx={{ base: 0, lg: 6 }}
              icon={<FiX fontSize="28px" />}
              boxSize="40px"
              aria-label="Close the Menu"
              onClick={onClose}
              variant="ghost"
              color={{ base: "primary.500", lg: "gray.500" }}
              borderRadius="99px"
            />
          </Flex>
        </Flex>
        <MainNav onClose={onClose} />
      </Stack>
      <Footer />
    </Flex>
  );
}

const StyledLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  :hover {
    text-decoration: none;
  }
`;

// Show an indication about the current environment, when we are not in production.
export const EnvironmentMark = () => {
  const envName = process.env.REACT_APP_ENV_NAME;
  const color = process.env.REACT_APP_ENV_COLOR || "primary.300";
  if (!envName) return null;

  return (
    <Box
      backgroundColor={color}
      position="absolute"
      m={0}
      bottom={{ base: "-5px", lg: "10px" }}
      right={0}
      px={1}
      py={0}
      color="white"
      textTransform="uppercase"
      fontSize={{ base: "10px", lg: "11px" }}
      fontWeight="bold"
      borderRadius="3px"
    >
      {envName}
    </Box>
  );
};

const CountBadge = (props: BoxProps) => {
  return (
    <Box position="absolute" top={2} right={4} color="gray.500" {...props} />
  );
};

const NavBox = styled(Box, {
  shouldForwardProp: prop => prop !== "screenSize"
})<{ screenSize: string }>`
  ${props => {
    if (["sm", "md"].includes(props.screenSize)) return;
    // This style is only applied to the desktop version of the sidebar.
    return `
     ul {
        border-top: 1px solid ${props.theme.colors.backgroundColor};
        border-bottom: 1px solid ${props.theme.colors.backgroundColor};

        > li + li {
          border-top: 1px solid ${props.theme.colors.backgroundColor};
        }
      }
    `;
  }}

  a {
    color: ${props => props.theme.colors.gray["800"]};
    display: block;
    outline: none;
  }
  a:hover,
  .active {
    font-weight: bold;
    color: ${props => props.theme.colors.primary["500"]};
    background-color: ${props =>
      ["sm", "md"].includes(props.screenSize)
        ? "transparent"
        : props.theme.colors.backgroundColor};
  }
  a:not(.active):hover {
    font-weight: ${props =>
      ["sm", "md"].includes(props.screenSize) ? "bold" : "normal"};
  }
  a:hover {
    text-decoration: none;
  }
  a:focus {
    box-shadow: none;
  }
`;

export const Logo = () => {
  const screenSize = useBreakpoint() ?? "";

  return (
    <Box
      display={{ base: "flex", lg: "flex" }}
      ml={["sm", "md"].includes(screenSize) ? "15px" : "25px"}
      height={{ base: "34px", lg: "80px" }}
      position="relative"
    >
      <StyledLink to="/">
        <PidPortLogo
          id={`sidebar-${screenSize}`}
          width={["sm", "md"].includes(screenSize) ? 94 : 140}
        />
      </StyledLink>
      <EnvironmentMark />
    </Box>
  );
};

const Nav = ({ children }) => {
  const screenSize = useBreakpoint() ?? "";
  return (
    <NavBox
      sx={{
        height: { base: "calc(100vh - 180px)", lg: "auto" },
        "&": {
          height: { base: "calc(100svh - 180px)", lg: "auto" }
        }
      }}
      display={{ base: "flex", lg: "block" }}
      alignItems="center"
      px={{ base: 4, lg: 0 }}
      screenSize={screenSize}
    >
      <List
        w="100%"
        h={{ base: "min(100%, 385px)", lg: "100%" }}
        mb={0}
        py={{ base: 4, lg: 0 }}
        display={{ base: "flex", lg: "block" }}
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        fontWeight={{ base: "bold", lg: "normal" }}
      >
        {children}
      </List>
    </NavBox>
  );
};

const NavItem = ({
  icon,
  children,
  ...props
}: { icon?: IconType } & LinkProps<unknown>) => {
  const screenSize = useBreakpoint() ?? "";

  return (
    <ListItem>
      <Link
        as={NavLink}
        {...props}
        pl={{ base: 0, lg: "25px" }}
        py={{ base: 0, lg: "15px" }}
        position="relative"
      >
        <Flex as="span" alignItems="center">
          {!["sm", "md"].includes(screenSize) && (
            <Icon as={icon} boxSize={6} mr={3} />
          )}
          {children}
        </Flex>
      </Link>
    </ListItem>
  );
};

const MainNav = ({ onClose }) => {
  const locale = useLocale();
  const history = useHistory();
  const screenSize = useBreakpoint() ?? "";
  const { imageTotal } = BulkUploadContainer.useContainer();
  const { rowTotal } = ImportCSVContainer.useContainer();
  const {
    menuList,
    isAdministratorRole,
    isMemberRole,
    isProprietorRole,
    isSuper,
    signOut
  } = useAccount();

  const defaultMenuList = [
    {
      menuName: "Groups",
      url: "/organizations",
      icon: FaRegBuilding
    },
    {
      menuName: "Cases",
      url: "/cases",
      icon: AiOutlineFileSearch
    },
    isMemberRole && {
      menuName: "Upload Files",
      url: "/bulk-upload",
      icon: RiFolderUploadLine
    },
    isAdministratorRole && {
      menuName: "Users",
      url: "/users",
      icon: FiUsers
    },
    isProprietorRole && {
      menuName: "Contracts",
      url: "/contracts",
      icon: CgFileDocument
    },
    isSuper() && {
      menuName: "Import CSV",
      url: "/import-csv",
      icon: RiFileUploadLine
    },
    isSuper() && {
      menuName: "Export CSV",
      url: "/export-csv",
      icon: RiFileDownloadLine
    }
  ].filter(value => !!value) as Medmain.Menu[];

  const userMenu = [
    {
      menuName: locale.userMenuAccountItemLabel,
      onClick: () => {
        history.push("/my-account");
        onClose();
      }
    },
    {
      menuName: locale.userMenuSignOutItemLabel,
      onClick: signOut
    }
  ];

  return (
    <Nav>
      {(menuList?.menuList.length
        ? (menuList?.menuList as Medmain.Menu[])
        : defaultMenuList
      ).map((item, index) => (
        <NavItem
          key={index}
          to={item.url}
          icon={defaultMenuList.find(icon => icon.url === item.url)?.icon}
        >
          {locale.todo(item.menuName)}
          {item.menuName === "Upload Files" && imageTotal > 0 && (
            <CountBadge>{imageTotal}</CountBadge>
          )}
          {item.menuName === "Import CSV" && rowTotal > 0 && (
            <CountBadge>{rowTotal}</CountBadge>
          )}
        </NavItem>
      ))}
      {["sm", "md"].includes(screenSize) && (
        <>
          <Divider color="dividerColor" />
          <Box
            display={{ base: "flex", md: "none" }}
            as="span"
            onClick={() => {
              window.open(
                process.env.REACT_APP_ZENDESK_HELP_CENTER_URL,
                "_blank"
              );
              onClose();
            }}
            alignItems="center"
            _hover={{
              cursor: "pointer"
            }}
          >
            Help
          </Box>
          {userMenu.map((menu, index) => (
            <Flex
              key={index}
              as="span"
              onClick={menu.onClick}
              alignItems="center"
              _hover={{
                cursor: "pointer"
              }}
            >
              {menu.menuName}
            </Flex>
          ))}
        </>
      )}
    </Nav>
  );
};
