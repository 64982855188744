/**
 * The IIIF tile source loader is ported from OpenSeaDragon
 *
 * Note: for simplicity, the compatibility of versions other than v2 have been removed.
 *
 * TODO
 * - bring back v3 support if in need
 */

/**
 * OpenSeadragon - IIIFTileSource
 *
 * Copyright (C) 2009 CodePlex Foundation
 * Copyright (C) 2010-2013 OpenSeadragon contributors
 *
 * Redistribution and use in source and binary forms, with or without
 * modification, are permitted provided that the following conditions are
 * met:
 *
 * - Redistributions of source code must retain the above copyright notice,
 *   this list of conditions and the following disclaimer.
 *
 * - Redistributions in binary form must reproduce the above copyright
 *   notice, this list of conditions and the following disclaimer in the
 *   documentation and/or other materials provided with the distribution.
 *
 * - Neither the name of CodePlex Foundation nor the names of its
 *   contributors may be used to endorse or promote products derived from
 *   this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
 * "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
 * LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
 * A PARTICULAR PURPOSE ARE DISCLAIMED.  IN NO EVENT SHALL THE COPYRIGHT
 * OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
 * SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED
 * TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR
 * PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF
 * LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
 * NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS
 * SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
 */

import { ViewState } from "components/viewer/types";
import GeoTIFF, { fromUrl, GeoTIFFImage, Pool } from "geotiff";
import * as z from "zod";

export type Point = {
  x: number;
  y: number;
};

// https://iiif.io/api/image/2.1/#technical-properties
export const IIIFTileSourceSchema = z
  .object({
    "@context": z.literal("http://iiif.io/api/image/2/context.json"),
    "@id": z.string(),
    "@type": z.literal("iiif:Image").optional(),
    protocol: z.literal("http://iiif.io/api/image"),
    width: z.number(),
    height: z.number(),
    profile: z.tuple([
      z.string(),
      z
        .object({
          // Omit other unused fields
          supports: z.array(z.string()).optional()
        })
        .nonstrict()
    ]),
    sizes: z
      .array(
        z.object({
          "@type": z.literal("iiif:Size").optional(),
          width: z.number(),
          height: z.number()
        })
      )
      .optional(),
    tiles: z
      .array(
        z.object({
          "@type": z.literal("iiif:Tile").optional(),
          scaleFactors: z.array(z.number()),
          width: z.number(),
          height: z.number().optional()
        })
      )
      .optional()
  })
  .nonstrict();

export type IIIFTileSourceSchema = z.infer<typeof IIIFTileSourceSchema>;

type Options = {
  maxLevel?: number;
  minLevel?: number;
  tileOverlap?: number;
};

export type TileSourceType = Pick<
  IIIFTileSourceSchema,
  "@id" | "width" | "height"
>;

export class IIIFTileSource {
  // Fix to IIIF v2
  version = 2;
  // Fix to jpg
  // TODO: should we accept other situations?
  tileFormat = "jpg";

  id: string;
  width: number;
  height: number;
  tileWidth: number = 512;
  tileHeight: number = 512;
  scaleFactors: number[] = [];
  tileSizePerScaleFactor: {
    [key: number]: { width: number; height: number };
  } = {};
  minLevel: number;
  maxLevel: number;
  aspectRatio: number;
  dimensions: Point;
  tileOverlap: number = 0;
  levelScaleCache: number[];
  tiff?: GeoTIFF;
  images: GeoTIFFImage[] = [];
  pool: Pool;

  constructor(
    info: TileSourceType & { tiff?: GeoTIFF; images?: GeoTIFFImage[] },
    options: Options = {}
  ) {
    this.id = info["@id"];
    this.width = info.width;
    this.height = info.height;
    this.tiff = info.tiff;
    this.images = info.images ?? [];

    // 512 as min tile
    const levelGap = 9;
    this.minLevel = options.minLevel ?? 0;
    this.maxLevel = Math.max(
      this.minLevel,
      options.maxLevel ??
        Math.ceil(Math.log(Math.max(this.width, this.height)) * Math.LOG2E) -
          levelGap
    );
    this.aspectRatio = this.width / this.height;
    this.dimensions = { x: this.width, y: this.height };
    this.tileOverlap = options.tileOverlap ?? 0;
    this.levelScaleCache = [...Array(this.maxLevel + 1)].map(
      (_, i) => 1 / Math.pow(2, this.maxLevel - i)
    );
    this.pool = new Pool();
  }

  static async build(info: TileSourceType, uri: string) {
    const tiff = await fromUrl(uri, {
      headers: {},
      cacheSize: Infinity,
      signal: undefined
    });
    const imageCount = await tiff.getImageCount();
    const images = await Promise.all(
      Array.from(new Array(imageCount)).map((_, i) => tiff.getImage(i))
    );
    return new IIIFTileSource({ ...info, tiff, images });
  }

  /**
   * Return the tileWidth for the given level.
   * @function
   * @param level
   */
  getTileWidth(level: number) {
    return this.tileWidth;
  }

  /**
   * Return the tileHeight for the given level.
   * @function
   * @param level
   */
  getTileHeight(level: number) {
    return this.tileHeight;
  }

  /**
   * @function
   * @param {Number} level
   */
  getLevelScale(level: number) {
    return this.levelScaleCache[level];
  }

  /**
   * @function
   * @param {Number} level
   */
  getNumTiles(level: number) {
    const scale = this.getLevelScale(level);
    const x = Math.ceil((scale * this.dimensions.x) / this.getTileWidth(level));
    const y = Math.ceil(
      (scale * this.dimensions.y) / this.getTileHeight(level)
    );
    return { x, y };
  }

  /**
   * @function
   * @returns The highest level in this tile source that can be contained in a single tile.
   */
  getClosestLevel() {
    return this.minLevel;
  }

  /**
   * @function
   * @param level
   * @param x
   * @param y
   * @returns Either where this tile fits (in normalized coordinates) or the
   * portion of the tile to use as the source of the drawing operation (in pixels), depending on
   * the isSource parameter.
   */
  getTileBounds(level: number, x: number, y: number) {
    const image = this.images[this.maxLevel - level];
    const dx = image.getWidth();
    const dy = image.getHeight();
    const tileWidth = this.getTileWidth(level);
    const tileHeight = this.getTileHeight(level);
    const px = x === 0 ? 0 : tileWidth * x - this.tileOverlap;
    const py = y === 0 ? 0 : tileHeight * y - this.tileOverlap;
    const sx = Math.min(
      tileWidth + (x === 0 ? 1 : 2) * this.tileOverlap,
      dx - px
    );
    const sy = Math.min(
      tileHeight + (y === 0 ? 1 : 2) * this.tileOverlap,
      dy - py
    );
    return [sx, sy];
  }

  /**
   * Responsible for retrieving the url which will return an image for the
   * region specified by the given x, y, and level components.
   * @function
   * @param {Number} level - z index
   * @param {Number} x
   * @param {Number} y
   * @throws {Error}
   */
  getTileUrl(level: number, x: number, y: number) {
    return this.tiff?.source || "";
  }

  async getTile(level: number, x: number, y: number) {
    const image = this.images[this.maxLevel - level];
    const tileWidth = this.getTileWidth(level);
    const tileHeight = this.getTileHeight(level);

    const x0 = x * tileWidth;
    const y0 = y * tileHeight;
    const x1 = Math.min(image.getWidth(), (x + 1) * tileWidth);
    const y1 = Math.min(image.getHeight(), (y + 1) * tileHeight);
    const width = Math.abs(x1 - x0);
    const height = Math.abs(y1 - y0);

    const data = await image.readRGB({
      window: [x0, y0, x1, y1],
      interleave: true,
      pool: this.pool
    });
    return { data, width, height };
  }

  tileExists(level: number, x: number, y: number) {
    const numTiles = this.getNumTiles(level);
    return (
      level >= this.minLevel &&
      level <= this.maxLevel &&
      x >= 0 &&
      y >= 0 &&
      x < numTiles.x &&
      y < numTiles.y
    );
  }

  /**
   * Following methods are for TileLayer usage
   * Some parameters are:
   * @param x tile index x, shared by both TileLayer and IIIF worlds
   * @param y tile index y, shared by both TileLayer and IIIF worlds
   * @param z tile index z, the adjusted zoom level by -this.maxLevel, in the TileLayer world
   * @param level zoom level used here, in iiif world
   */

  /**
   * Get level from z
   * @param z tile index z
   */
  getLevel(z: number) {
    return z + this.maxLevel;
  }

  /**
   * Get the tile index z to fix in the bounding box specified by width and height
   * @param width the width of the bounding box, window e.g., to be fix in
   * @param height the height of the bounding box, window e.g., to be fix in
   */
  getFitZ(width: number, height: number) {
    return (
      Math.log(Math.min(width / this.width, height / this.height)) * Math.LOG2E
    );
  }

  /**
   * Get the calculated bounding box, with [level, x, y]
   * @param level zoom level
   * @param x tile index x
   * @param y tile index y
   */
  getTileBoundingBox(
    level: number,
    x: number,
    y: number
  ): [number, number, number, number] {
    const dx = x * this.getTileWidth(level);
    const dy = y * this.getTileHeight(level);
    const [w, h] = this.getTileBounds(level, x, y);
    const scale = this.getLevelScale(level);
    const left = dx / scale;
    const top = dy / scale;
    const right = (dx + w) / scale;
    const bottom = (dy + h) / scale;
    return [left, bottom, right, top];
  }

  // Return named version of getTileBoundingBox
  getTileNamedBoundingBox(level: number, x: number, y: number) {
    const [left, bottom, right, top] = this.getTileBoundingBox(level, x, y);
    return { left, bottom, right, top };
  }

  /**
   * Get the viewstate that fits the image inside width x height dimensions, for DeckGL use
   * @param width the viewport width
   * @param height the viewport height
   */
  getFitViewState(width: number, height: number): Partial<ViewState> {
    // Use original size as max zoomed level, thus offset zoom range by -iiif.maxLevel
    // The offset needs to be applied to iiif as well, to get correct result
    const zoom = this.getFitZ(width, height);
    // Choose the max value between floored fit or minimum iiif level, as the min zoom level
    const minZoom = Math.max(Math.floor(zoom), this.minLevel - this.maxLevel);
    // Digital zoom® : allow 2x over-zooming, i.e. which increase 20x to 40x with 20x-40x as zoomed result of 20x
    const maxZoom = 1;
    return {
      minZoom,
      maxZoom,
      zoom: Math.min(Math.max(zoom, minZoom), maxZoom),
      target: [this.width / 2, this.height / 2, 0],
      rotationOrbit: 0
    };
  }

  async getThumbnail() {
    const { data, width, height } = await this.getTile(
      this.getClosestLevel(),
      0,
      0
    );
    const imageData = new ImageData(width, height);
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const i = (y * width + x) * 4;
        const j = (y * width + x) * 3;
        imageData.data[i] = data[j] as number;
        imageData.data[i + 1] = data[j + 1] as number;
        imageData.data[i + 2] = data[j + 2] as number;
        imageData.data[i + 3] = 255;
      }
    }
    const image = await createImageBitmap(imageData, 0, 0, width, height);
    return { data, width, height, image };
  }
}
