import React from "react";
import { Box, ButtonGroup, Flex, useBreakpoint } from "@chakra-ui/react";
import { Pagination } from "antd";
import styled from "@emotion/styled";

import { useLocale } from "app/locale";
import { parseInteger, usePagination } from "app/pagination";

type Props = Parameters<typeof usePagination>[0] & {
  children?: React.ReactNode;
};

export const PAGINATION_SIZES = ["10", "20", "50", "100"];
export const PAGINATION_MINIMUM_LIMIT = parseInteger(PAGINATION_SIZES[0], {
  defaultValue: 10,
  minValue: 1
});

const Container = styled(Box, {
  shouldForwardProp: prop => prop !== "screenSize"
})<{ screenSize: string }>`
  .anticon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ screenSize }) =>
    screenSize === "sm" &&
    `
      .ant-pagination-options {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-top: 10px
      }
    `}
`;

export const PaginatedListActionBar = ({
  children,
  searchOptions,
  total
}: Props) => {
  const { updateSearchParams } = usePagination({
    searchOptions,
    total
  });
  const screenSize = useBreakpoint() ?? "";

  function onShowSizeChange(current, pageSize) {
    updateSearchParams({
      page: current,
      limit: pageSize
    });
  }

  return (
    <Flex mt="5" justifyContent={{ base: "center", md: "initial" }}>
      {children}

      <Box flex="1" d={{ base: "none", md: "block" }} />

      {total > PAGINATION_MINIMUM_LIMIT && (
        <Container screenSize={screenSize}>
          <ButtonGroup>
            <Pagination
              showSizeChanger
              onChange={(page, pageSize) => {
                if (searchOptions.limit === pageSize) {
                  updateSearchParams({ page });
                }
              }}
              current={searchOptions.page}
              pageSize={searchOptions.limit ?? PAGINATION_MINIMUM_LIMIT}
              onShowSizeChange={onShowSizeChange}
              total={total}
              pageSizeOptions={PAGINATION_SIZES}
            />
          </ButtonGroup>
        </Container>
      )}
    </Flex>
  );
};

export const PaginationNumbers = ({ total, offset, limit }) => {
  const locale = useLocale();
  return (
    <>
      {locale.formatNumber(offset + 1)}-
      {locale.formatNumber(Math.min(offset + limit, total))} of{" "}
      {locale.formatNumber(total)}
    </>
  );
};
