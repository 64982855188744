import { useState } from "react";
import { useCookie } from "react-use";
import { createContainer } from "unstated-next";
import add from "date-fns/add";
import debugModule from "debug";

import { useAccount } from "app/auth-container";
import { readEnvVariable } from "api/config";

const debug = debugModule("medmain:legal");
const AGREEMENT_DURATION_IN_DAYS = 30; // the user is supposed to renew the agreement every N days
const DISMISSED_WARNING_DURATION_IN_DAYS = 30; // when the user dismisses the warning in cases page, it's hidden for N days

type Status = "invalid" | "valid" | "skipped"; // TODO check if we really need "valid" and "invalid" statuses as only "skipped" is used

// For orgs with AI prediction enabled, we have to show a required agreement before the user can proceed
export function useAgreement() {
  const { getCurrentOrg } = useAccount();
  const [value, updateCookie] = useCookie("pidport-ai-agreement");
  const [status, setStatus] = useState<Status | null>(null);

  function shouldAskAgreement() {
    const currentOrg = getCurrentOrg();

    if (status === "skipped") return false;
    if (!currentOrg) return false; // no legal stuff when no org is selected (only available for super users)
    if (isMedmainOrg(currentOrg.id)) return false;
    if (!currentOrg?.predictionEnabled) return false;

    return !value;
  }

  function agree() {
    const expiresAt = getExpirationDate(AGREEMENT_DURATION_IN_DAYS);
    updateCookie("agreed", { expires: expiresAt });
  }

  function skip() {
    setStatus("skipped");
  }

  const canUsePredictionFeatures = status !== "skipped";

  // Heat-map and threshold features are now allowed for all users
  // TODO rename this accordingly?
  const canUseMedmainAIFeatures = true;

  return {
    agree,
    canUseMedmainAIFeatures,
    canUsePredictionFeatures,
    shouldAskAgreement,
    skip,
    status
  };
}

export const AgreementContainer = createContainer(useAgreement);

// For orgs with AI prediction enabled, we have to show warnings on top of the cases pages (list and details)
export function useCaseLegalWarning() {
  const { status } = AgreementContainer.useContainer();
  const { getCurrentOrg } = useAccount();
  const [value, updateCookie] = useCookie("pidport-ai-warning");
  const DISMISSED_WARNING = "dismissed";

  function shouldShowWarning() {
    const currentOrg = getCurrentOrg();

    if (status === "skipped") return false;
    if (!currentOrg) return false; // no legal stuff when no org is selected (only available for super users)
    if (isMedmainOrg(currentOrg.id)) return false;
    if (!currentOrg?.predictionEnabled) return false;

    return value !== DISMISSED_WARNING;
  }

  function hide() {
    const expiresAt = getExpirationDate(DISMISSED_WARNING_DURATION_IN_DAYS);
    updateCookie(DISMISSED_WARNING, { expires: expiresAt });
  }

  return { shouldShowWarning, hide };
}

export function usePredictionWarning() {
  const { getCurrentOrg } = useAccount();

  function shouldShowWarning() {
    const currentOrg = getCurrentOrg();

    if (!currentOrg) return false; // no legal stuff when no org is selected (only available for super users)
    if (isMedmainOrg(currentOrg.id)) return false;

    return true;
  }
  return { shouldShowWarning };
}

// ...but we skip the legal stuff for special Medmain orgs that are hard-coded for now
export function isMedmainOrg(organizationId: Medmain.Organization["id"]) {
  const orgIdsAsString = readEnvVariable("SKIP_AI_AGREEMENT_ORGS");
  const orgs = orgIdsAsString.split(",");
  const isSpecialOrg = orgs.includes(organizationId);
  if (isSpecialOrg) {
    debug("Skip AI legal agreement and warning for Medmain AI orgs");
  }
  return isSpecialOrg;
}

function getExpirationDate(days: number) {
  return add(new Date(), { days });
}
