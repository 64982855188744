import React from "react";
import { Box, Stack } from "@chakra-ui/react";

import { useLocale } from "app/locale";
import { useAccount, useOrgLookup } from "app/auth-container";
import { hasPermission } from "app/permissions";
import { Button, EditIcon, DateTime } from "components/core";
import { ReadonlyField } from "components/common/fields";
import { TagLink } from "components/cases/case-tags";
import { useSearch } from "app/search";

type Props = {
  pathologicCase: Medmain.Case;
  setEditMode?: (value: boolean) => void;
};
export const CaseDetailsReadonly = ({ pathologicCase, setEditMode }: Props) => {
  const locale = useLocale();
  const { isSuper } = useAccount();
  const {
    caseNumber,
    createdAt,
    comments,
    diagnosis,
    grossFindings,
    intendedOwnerOrganizationId,
    microscopicFindings,
    tags
  } = pathologicCase;
  const canUpdate = hasPermission("case/update", pathologicCase);
  const { getOrgById } = useOrgLookup();

  return (
    <Box wordBreak="break-all">
      {isSuper() && (
        <ReadonlyField label={locale.caseFields.slideProvider}>
          {(intendedOwnerOrganizationId &&
            getOrgById(intendedOwnerOrganizationId)?.name) ||
            intendedOwnerOrganizationId}
        </ReadonlyField>
      )}
      <ReadonlyField label={locale.caseFields.caseNumber}>
        {caseNumber}
      </ReadonlyField>
      <ReadonlyField label={locale.caseFields.createdAt}>
        <DateTime date={createdAt} />
      </ReadonlyField>
      <ReadonlyField label={locale.caseFields.diagnosis}>
        {diagnosis}
      </ReadonlyField>
      <ReadonlyField label={locale.caseFields.tags}>
        {tags.length && <TagLinkList tags={tags} />}
      </ReadonlyField>
      <ReadonlyField label={locale.caseFields.grossFindings}>
        {grossFindings}
      </ReadonlyField>
      <ReadonlyField label={locale.caseFields.microscopicFindings}>
        {microscopicFindings}
      </ReadonlyField>
      <ReadonlyField label={locale.caseFields.comments}>
        {comments}
      </ReadonlyField>
      {canUpdate && setEditMode && (
        <Box mt={4}>
          <Button
            onClick={() => setEditMode(true)}
            leftIcon={<EditIcon />}
            variant="outline"
            colorScheme="primary"
            w="100%"
          >
            {locale.editButtonLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const TagLinkList = ({ tags }: { tags: string[] }) => {
  const { setQueryField } = useSearch();
  return (
    <Stack>
      {tags.map(tag => {
        const tagLocation = {
          ...setQueryField("tags", [tag], "contains-any"),
          pathname: "/cases"
        };
        return (
          <TagLink key={tag} to={tagLocation} title={tag}>
            {tag}
          </TagLink>
        );
      })}
    </Stack>
  );
};
