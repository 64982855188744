import { ColumnsType } from "antd/es/table";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, HStack, Link } from "@chakra-ui/react";

import { useLocale } from "app/locale";
import {
  DateTime,
  DeleteIcon,
  DownloadIcon,
  IconButton,
  Progress,
  Table
} from "components/core";
import { isPDF } from "utils/files";

export class UploadingAttachment {
  id: string;
  filename: string;
  contentType: string;
  sizeBytes: number;
  createdAt = new Date().toISOString();
  progress = 0;

  constructor(file: File) {
    this.id = "";
    this.filename = file.name;
    this.contentType = file.type;
    this.sizeBytes = file.size;
  }

  setProgress(progress: number) {
    this.progress = progress;
    return this;
  }
}

type Props = {
  caseId: Medmain.Case["id"];
  attachments: Medmain.Attachment[];
  canAddAttachment: boolean;
  onDeleteAttachment: (attachment) => void;
  onDownloadAttachment: (attachment) => void;
};
export const CaseAttachmentList = ({
  caseId,
  attachments,
  canAddAttachment,
  onDeleteAttachment,
  onDownloadAttachment
}: Props) => {
  const locale = useLocale();
  const location = useLocation();

  const columns: ColumnsType<Medmain.Attachment> = [
    {
      title: locale.todo("Name"),
      dataIndex: "filename",
      render: (_, attachment) => {
        return (
          <Box
            overflow="hidden"
            display="-webkit-box"
            textOverflow="ellipsis"
            minW={{ sm: "120px", xl2: "240px", xl3: "340px" }}
            wordBreak="break-word"
            css={{
              "-webkit-box-orient": "vertical",
              "-webkit-line-clamp": "3"
            }}
          >
            {attachment instanceof UploadingAttachment ||
            !isPDF(attachment.filename) ? (
              attachment.filename
            ) : (
              <>
                <Link
                  as={RouterLink}
                  to={{
                    ...location,
                    pathname: `/cases/${caseId}/attachments/${encodeURI(
                      attachment.filename
                    )}/${attachment.id}`
                  }}
                >
                  {attachment.filename}
                </Link>
              </>
            )}
          </Box>
        );
      }
    },
    {
      title: locale.todo("Size"),
      dataIndex: "sizeBytes",
      width: 100,
      render: (v: Medmain.Attachment["sizeBytes"]) => locale.formatFileSize(v)
    },
    {
      title: locale.todo("Upload"),
      dataIndex: "uploadDate",
      width: 100,
      render: (_, attachment) => {
        return <DateTime date={attachment.createdAt} mask="yyyy-MM-dd" />;
      }
    },
    {
      key: "operations",
      width: 50,
      render: (_, x) => {
        if (x instanceof UploadingAttachment) {
          return <Progress value={x.progress} max={1} w="100%" />;
        }
        return (
          <HStack>
            <IconButton
              onClick={event => {
                event.stopPropagation(); // don't trigger a row click event
                onDownloadAttachment(x);
              }}
              aria-label="Download"
              icon={<DownloadIcon />}
            />
            {canAddAttachment && (
              <IconButton
                onClick={event => {
                  event.stopPropagation(); // don't trigger a row click event
                  onDeleteAttachment(x);
                }}
                aria-label="Delete"
                icon={<DeleteIcon />}
              >
                {/* Delete */}
              </IconButton>
            )}
          </HStack>
        );
      }
    }
  ];
  return (
    <Table
      rowKey="filename"
      columns={columns}
      dataSource={attachments}
      size="small"
      pagination={false}
      shouldShowHoverBackground={true}
      scroll={{ x: true }}
      rowClassName={attachment => {
        return attachment instanceof UploadingAttachment ? "" : "clickable-row";
      }}
    />
  );
};
