import React from "react";
import { Box, BoxProps, Flex } from "@chakra-ui/react";

import { InfoIcon } from "components/core";
import { usePredictionWarning } from "./agreement";
import { ShowDetailsButton } from "./dialog-details";

export const PredictionAIWarning = (props: BoxProps) => {
  const { shouldShowWarning } = usePredictionWarning();

  if (!shouldShowWarning()) return null;

  return (
    <Flex fontSize="md" alignItems="center" bg="blue.50" px={6} py={2}>
      <InfoIcon mr={1} color="blue.500" fontSize="20px" />
      <Box>The use of AI analysis results is subject to restrictions.</Box>
      <ShowDetailsButton color="blue.600" />
    </Flex>
  );
};

export const PredictionPanelLegalWarning = ({
  showIcon,
  ...props
}: { showIcon?: boolean } & BoxProps) => {
  const { shouldShowWarning } = usePredictionWarning();
  if (!shouldShowWarning()) return null;

  return (
    <Box {...props}>
      {showIcon && <InfoIcon mr={1} />}
      The use of AI analysis results is subject to restrictions.
      <ShowDetailsButton variant="link" color="white" display="inline" />
    </Box>
  );
};
