import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  BoxProps,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useBreakpointValue
} from "@chakra-ui/react";

import { useLocale } from "app/locale";
import { useAccount } from "app/auth-container";
import { getAccountDisplayName } from "models/account";
import { Avatar } from "components/core";

export const UserMenu = () => {
  const history = useHistory();
  const locale = useLocale();
  const { account, signOut } = useAccount();

  const device = useBreakpointValue({
    base: "pc",
    sm: "sp",
    md: "tablet",
    pc: "pc"
  });
  if (!account) return null;
  const displayName = getAccountDisplayName(account, locale);

  return (
    <Popover placement="bottom-end">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              display="flex"
              h="40px"
              variant={device === "sp" ? "unstyled" : "outline"}
              isDisabled={device === "sp"}
              _disabled={{ opacity: 1 }}
              _focus={{ outline: "none" }}
            >
              {device !== "sp" && <Box mr={2}>{displayName}</Box>}
              <Avatar size="sm" name={displayName} src={account.avatarUrl} />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            zIndex={10000}
            maxW="200px"
            ml="auto"
            _focus={{ outline: "none" }}
          >
            <Box py={2}>
              <Menu
                items={[
                  {
                    label: locale.userMenuAccountItemLabel,
                    onClick: () => {
                      history.push("/my-account");
                      onClose && onClose();
                    },
                    disabled: false
                  },
                  {
                    label: locale.userMenuSignOutItemLabel,
                    onClick: signOut
                  }
                ]}
              />
            </Box>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

const Menu = ({ items }) => {
  return items.map(({ label, onClick }) => (
    <MenuItem key={label} onClick={onClick}>
      {label}
    </MenuItem>
  ));
};

const MenuItem = (props: BoxProps) => {
  return (
    <Box
      {...props}
      px={4}
      py={2}
      cursor="pointer"
      _hover={{ backgroundColor: "gray.100" }}
    />
  );
};
