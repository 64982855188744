import React from "react";
import {
  Box,
  ButtonGroup,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList
} from "@chakra-ui/react";

import { Button, CheckIcon } from "components/core";
import { AIIcon } from "components/image-viewer/plugins/icons";
import { AgreementContainer } from "./agreement";

export const Agreement = () => {
  const { agree, skip, shouldAskAgreement } = AgreementContainer.useContainer();

  const isOpen = shouldAskAgreement();

  return <AgreementModal isOpen={isOpen} onAgree={agree} onCancel={skip} />;
};

export const AgreementModal = ({ isOpen, onAgree, onCancel }) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => {}}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems="center" justifyContent="space-between">
            Image Analysis AI
          </Flex>
        </ModalHeader>
        <ModalBody fontSize="16px">
          <Stack>
            <Flex>
              <Box color="primary.200" pr={4}>
                <AIIcon size={48} />
              </Box>
              <Stack>
                <Text>
                  The user has accepted to use the AI analysis in the following
                  purpose.
                </Text>
                <UnorderedList spacing={2} pl={4}>
                  <ListItem>Medical education and training</ListItem>
                  <ListItem>Research in physics and chemistry science</ListItem>
                </UnorderedList>
                <Text>
                  The user has accepted not to use the AI analysis in the
                  following purpose.
                </Text>
                <UnorderedList spacing={2} pl={4}>
                  <ListItem>
                    Prevention, diagnosis and treatment activity toward the
                    human and animal
                  </ListItem>
                  <ListItem>
                    Having an effect on the physical structure and the function
                    of human and animal
                  </ListItem>
                </UnorderedList>
              </Stack>
            </Flex>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onCancel} size="lg">
              Disagree
            </Button>
            <Button
              onClick={onAgree}
              size="lg"
              primary
              leftIcon={<CheckIcon />}
              autoFocus
            >
              Agree
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
