import React from "react";
import styled from "@emotion/styled";
import { Box, Flex, Text } from "@chakra-ui/react";

import { MedmainLogo } from "./medmain-logo";

export const Footer = () => {
  return (
    <Box
      fontSize="sm"
      mx={{ base: "auto", lg: 0 }}
      my={0}
      pl={{ lg: "25px" }}
      pb={{ lg: "30px" }}
      color="gray.500"
    >
      <Flex mb={2}>
        <FooterLink href="https://medmain.com/" target="_blank">
          <MedmainLogo fontSize="24px" />
        </FooterLink>
      </Flex>
      <Text>© Medmain Inc.</Text>
    </Box>
  );
};

const FooterLink = styled.a`
  color: inherit;
`;
