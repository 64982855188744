import React from "react";
import { Box, Flex, useDisclosure, useBreakpoint } from "@chakra-ui/react";

import { Header } from "./header";
import { Sidebar } from "./sidebar";

type Props = {
  children: React.ReactNode;
};

export const SIDEBAR_WIDTH = 190;

export const Layout = ({ children }: Props) => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const screenSize = useBreakpoint() ?? "";

  return (
    <Box position="relative">
      <Box>
        <Header onClose={onClose} onToggle={onToggle} />
        <Flex align="flex-start">
          <Box mr={{ lg: SIDEBAR_WIDTH }}>
            <Sidebar
              width={["sm", "md"].includes(screenSize) ? "full" : SIDEBAR_WIDTH}
              isOpen={isOpen}
              onClose={onClose}
            />
          </Box>
          <Box
            minWidth={0}
            w="100%"
            p={{ base: "65px 15px 30px", lg: "80px 30px 30px 40px" }}
          >
            {children}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}
