import partition from "lodash/partition";
import React, { useState } from "react";
import { useAsync } from "react-async";
import { useToast } from "@chakra-ui/react";
import { useAPI } from "api";
import { useLocale } from "app/locale";
import { useModal } from "components/core";
import { DialogOrganModel } from "components/cases/dialog-organ-model";
import { DialogSkippedAIAnalysis } from "components/cases/dialog-skipped-ai-analysis";

export const useBatchProcessing = () => {
  const toast = useToast();
  const api = useAPI();
  const locale = useLocale();
  const modal = useModal();
  const [checkBox, setCheckBox] = useState<string[]>([]);

  const onToggleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBox(
      checkBox.includes(event.target.value)
        ? checkBox.filter(item => item !== event.target.value)
        : [...checkBox, event.target.value]
    );
  };

  const onCheckBoxAll = (value: string[]) => {
    if (checkBox.length > 0) {
      setCheckBox([]);
    } else {
      setCheckBox(value);
    }
  };

  const retrieveCheckedImages = (
    images: (Medmain.Image & { caseNumber: Medmain.Case["caseNumber"] })[],
    key: keyof Medmain.Image
  ) =>
    checkBox.flatMap(checkedItem =>
      images.filter(image => image[key] === checkedItem)
    );

  const { run, isPending, isRejected, isFulfilled, reload } = useAsync({
    deferFn: data => {
      return api.predictions.createBulk(data[0]);
    },
    onResolve: () => {
      toast({
        title: locale.todo("AI analysis of the selected images has started"),
        status: "success",
        isClosable: true
      });
    },
    onReject: () => {
      toast({
        title: locale.todo("Failed to start AI analysis of selected images"),
        status: "error",
        isClosable: true
      });
    },
    onCancel: () => {
      reload();
    }
  });

  const startAIAnalysis = async (
    organizationId: Medmain.Organization["id"],
    checkedImages: (Medmain.Image & {
      caseNumber: Medmain.Case["caseNumber"];
    })[]
  ) => {
    const partitionImages = partition(
      checkedImages,
      image =>
        image?.modelName &&
        image?.modelName !== image?.predictionSummary?.modelName
    );
    const predictionImages = partitionImages[0].map(image => ({
      imageId: image.id,
      modelName: image.modelName
    }));

    predictionImages.length > 0
      ? run({
          organizationId,
          predictionImages
        })
      : toast({
          title: locale.todo("No images available for AI analysis"),
          status: "error",
          isClosable: true
        });

    if (partitionImages[1].length === 0) return;
    await modal.dialog({
      modalProps: { size: "3xl", isCentered: true },
      render: () => <DialogSkippedAIAnalysis images={partitionImages[1]} />
    });
  };

  const updateAllOrganModel = async (
    checkedImages: Medmain.Image[],
    reload: () => void
  ) => {
    const updated = await modal.dialog({
      modalProps: { size: "3xl", isCentered: true },
      render: close => <DialogOrganModel images={checkedImages} close={close} />
    });
    if (updated) {
      toast({
        description: locale.todo("The images has been updated"),
        status: "info",
        isClosable: true
      });
      if (reload) {
        reload();
      }
    }
  };

  return {
    checkBox,
    setCheckBox,
    onToggleCheckBox,
    onCheckBoxAll,
    retrieveCheckedImages,
    startAIAnalysis,
    updateAllOrganModel,
    isPending,
    isRejected,
    isFulfilled
  };
};
