export function compareStringWithNumber(
  a: string,
  b: string,
  order: "ASC" | "DESC" = "ASC"
) {
  const regex = /(\d+)|(\D+)/g;
  const partsA = a.match(regex) || [];
  const partsB = b.match(regex) || [];

  while (partsA.length > 0 || partsB.length > 0) {
    const partA = partsA.shift() || "";
    const partB = partsB.shift() || "";
    const numA = parseInt(partA, 10);
    const numB = parseInt(partB, 10);

    if (!isNaN(numA) && !isNaN(numB)) {
      const diff = numA - numB;
      if (diff !== 0) {
        return order === "ASC" ? diff : -diff;
      }
    } else {
      const diff = partA.localeCompare(partB);
      if (diff !== 0) {
        return order === "ASC" ? diff : -diff;
      }
    }
  }

  return 0;
}

export function sortStringWithNumber<T>(
  array: T[],
  priorities: Medmain.Sort[]
) {
  return [...array].sort((a, b) => {
    for (const priority of priorities) {
      const { field, order } = priority;
      return compareStringWithNumber(a[field], b[field], order);
    }
    return 0;
  });
}
