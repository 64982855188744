import React from "react";
import { Flex, Box, BoxProps, useColorMode, ColorMode } from "@chakra-ui/react";
import { Table as AntdTable } from "antd";
import { TableProps as AntdTableProps } from "antd/es/table";
import styled from "@emotion/styled";

import { Spinner } from "./spinner";
import { ChevronDownIcon, ChevronRightIcon, SearchIcon } from "./icons";

export type WrapperProps = {
  shouldShowHoverBackground?: boolean;
  colorMode?: ColorMode;
};
const Wrapper = styled.div<WrapperProps>`
  ${props =>
    props.colorMode === "dark" &&
    `
    .ant-table {
      &,
      .ant-table-thead > tr > th,
      .ant-table-cell-fix-left, .ant-table-cell-fix-right,
      .ant-table-tbody > tr.ant-table-row:hover > td.ant-table-cell-fix-left,
      .ant-table-tbody > tr.ant-table-row:hover > td.ant-table-cell-fix-right,
      .ant-table-tbody > tr.ant-table-placeholder:hover > td
      {
        background-color: ${props.theme.colors.gray[700]};
      }
      .ant-empty-normal,
      .ant-table-thead > tr > th {
        color: ${props.theme.colors.whiteAlpha[800]};
      }
      td.ant-table-column-sort
      {
        background-color: ${props.theme.colors.blackAlpha[300]};
      }
      .ant-table-tbody > tr.ant-table-row:hover > td {
        background-color: ${props.theme.colors.blackAlpha[400]};
      }
    }
    `}
  .ant-table tbody > tr.ant-table-row:hover > td {
    ${props =>
      !props.shouldShowHoverBackground && "background-color: transparent;"}
  }
  .ant-table-thead > tr > .ant-table-row-expand-icon-cell,
  .ant-table-tbody > tr > .ant-table-row-expand-icon-cell {
    padding: 0;
  }
  .ant-table-expand-icon-col {
    width: 1rem;
  }
  .clickable-row {
    cursor: pointer;
  }
`;

// Omit unsupported props, augment supported.
export type TableProps<T> = Omit<AntdTableProps<T>, "loading"> & {
  isLoading?: boolean;
  indicator?: React.ReactElement;
} & WrapperProps;

export function Table<Record extends object = any>(props: TableProps<Record>) {
  const {
    isLoading,
    indicator = <Spinner />,
    shouldShowHoverBackground = true,
    ...rest
  } = props;
  const antdTableProps: AntdTableProps<Record> = {
    ...rest,
    loading: isLoading ? { spinning: true, indicator } : undefined
  };
  const { colorMode } = useColorMode();
  return (
    <Wrapper
      shouldShowHoverBackground={shouldShowHoverBackground}
      colorMode={colorMode}
    >
      <AntdTable<Record> {...antdTableProps} />
    </Wrapper>
  );
}

export const ExpandIcon = ({
  expanded,
  ...props
}: { expanded: boolean } & BoxProps) => (
  <Box
    color="gray.500"
    _hover={{ color: "primary.500" }}
    cursor="pointer"
    {...props}
  >
    {expanded ? (
      <ChevronDownIcon boxSize="28px" />
    ) : (
      <ChevronRightIcon boxSize="28px" />
    )}
  </Box>
);

export const TableSearchIcon = ({ filtered }: { filtered: boolean }) => {
  return (
    <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
      <SearchIcon color={filtered ? "primary.500" : "inherit"} />
    </Flex>
  );
};
