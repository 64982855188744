import React, { useState } from "react";
import {
  Box,
  ButtonGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  Text,
  useToast
} from "@chakra-ui/react";
import { useAsync } from "react-async";

import { useLocale } from "app/locale";
import { useAPI } from "api";
import { Button } from "components/core";
import { useOrgLookup } from "app/auth-container";
import { TransferableOrgPicker } from "components/organizations/transferable-org-picker";

export const DialogTransferCase = ({ pathologicCase, close, reload }) => {
  const api = useAPI();
  const locale = useLocale();
  const { getOrgById } = useOrgLookup();
  const [selectedOrgId, setSelectedOrgId] = useState(undefined);
  const { caseNumber, id } = pathologicCase;
  const toast = useToast();
  const executeTransfer = async () => {
    try {
      await api.cases.transferOwnership(id, selectedOrgId!);
      toast({
        description: `Case "${caseNumber}" transferred`,
        status: "success",
        isClosable: true
      });
    } catch (error) {
      toast({
        title: "Unable to transfer the ownership",
        description: error.message || "Unexpected error",
        status: "error"
      });
    }
    reload();
  };
  const { run: transferCase, isPending } = useAsync({
    deferFn: () => executeTransfer(),
    onResolve: () => close(true)
  });
  const onSubmit = event => {
    event.preventDefault();
    transferCase();
  };

  return (
    <form onSubmit={onSubmit}>
      <ModalHeader>
        {locale.todo("Transfer Ownership")}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text>
          {`Do you want to transfer the ownership of the case ${caseNumber} to ${
            selectedOrgId ? getOrgById(selectedOrgId!).name : ""
          }?`}
        </Text>
        <Box w="100%" mt={4}>
          <TransferableOrgPicker
            setSelectedOrgId={setSelectedOrgId}
            selectedOrgId={selectedOrgId}
          />
        </Box>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={() => close(false)} isDisabled={isPending}>
            {locale.todo("Cancel")}
          </Button>
          <Button
            type="submit"
            primary
            isLoading={isPending}
            isDisabled={isPending || selectedOrgId === undefined}
          >
            {locale.todo("Transfer Ownership")}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};
