import React from "react";
import { Badge, BadgeProps, Box, BoxProps } from "@chakra-ui/react";

import { useLocale } from "app/locale";
import { useAccount } from "app/auth-container";
import { OrgPermissionType, hasPermission } from "app/permissions";
import { Select, SelectProps } from "components/core/select";
import { isMedmainOrg } from "features/ai-legal-terms/agreement";
import { compareStringWithNumber } from "../../utils/sort";

type Props = {
  permissionType?: OrgPermissionType;
  defaultOrgId?: string | null;
  onChange: (orgId: any) => void;
  showAllOption?: boolean;
  persistOrgChoice?: boolean;
} & SelectProps<string>;
export const OrgPicker = ({
  permissionType,
  defaultOrgId,
  onChange,
  showAllOption = false,
  persistOrgChoice = true,
  ...otherProps
}: Props) => {
  const locale = useLocale();
  const { visibleOrgs, setCurrentOrgId } = useAccount();

  const filteredOrgs = [
    ...(permissionType
      ? visibleOrgs.filter(org => hasPermission(permissionType, org))
      : visibleOrgs)
  ].sort((a, b) => compareStringWithNumber(a.name, b.name));

  // if the user is partner of an org, the current organization does not allow case creation, so we have to ignore the default value
  const isValidDefaultValue = filteredOrgs.find(
    ({ id }) => id === defaultOrgId
  );

  return (
    <Select
      value={isValidDefaultValue && !!defaultOrgId ? defaultOrgId : undefined}
      showSearch={filteredOrgs.length > 5}
      filterOption={filterOption}
      placeholder={locale.todo("Pick a group")}
      {...otherProps}
      onChange={(value: string) => {
        if (persistOrgChoice) {
          setCurrentOrgId(value);
        }
        onChange(value);
      }}
    >
      {filteredOrgs.map(org => (
        <Select.Option key={org.id} value={org.id} label={org.name}>
          <OrgOption key={org.id} org={org} />
        </Select.Option>
      ))}
    </Select>
  );
};

const OrgOption = ({ org }: { org: Medmain.Organization }) => {
  return (
    <Box>
      {org.name}
      <PredictionStatus org={org} ml={2} />
    </Box>
  );
};

const PredictionStatus = ({
  org,
  ...props
}: { org: Medmain.Organization } & BoxProps) => {
  if (isMedmainOrg(org.id)) {
    return (
      <OrgBadge colorScheme="blue" {...props}>
        Medmain AI
      </OrgBadge>
    );
  }

  if (org.predictionEnabled) {
    return (
      <OrgBadge colorScheme="primary" {...props}>
        AI
      </OrgBadge>
    );
  }

  return null;
};

const OrgBadge = (props: BadgeProps) => {
  return <Badge display="inline" variant="solid" fontSize="12px" {...props} />;
};

// It seems we need a custom function otherwise options are filtered by value, which is not useful at all!
function filterOption(input: string, option): boolean {
  return option.label.toLowerCase().includes(input.toLowerCase());
}
