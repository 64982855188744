import React from "react";
import {
  Box,
  ButtonProps,
  CloseButton,
  Flex,
  ListItem,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Stack,
  Text,
  UnorderedList
} from "@chakra-ui/react";

import { Button, useModal } from "components/core";
import { AIIcon } from "components/image-viewer/plugins/icons";

export const DialogDetails = ({ onClose }) => {
  return (
    <>
      <ModalHeader display="flex" justifyContent="space-between">
        Image Analysis AI
        <CloseButton onClick={onClose} />
      </ModalHeader>
      <ModalBody>
        <Flex>
          <Box color="primary.200" pr={4}>
            <AIIcon size={48} />
          </Box>
          <Stack>
            <Text>
              The user has accepted to use the AI analysis in the following
              purpose.
            </Text>
            <UnorderedList spacing={2} pl={4}>
              <ListItem>Medical education and training</ListItem>
              <ListItem>Research in physics and chemistry science</ListItem>
            </UnorderedList>
            <Text>
              The user has accepted not to use the AI analysis in the following
              purpose.
            </Text>
            <UnorderedList spacing={2} pl={4}>
              <ListItem>
                Prevention, diagnosis and treatment activity toward the human
                and animal
              </ListItem>
              <ListItem>
                Having an effect on the physical structure and the function of
                human and animal
              </ListItem>
            </UnorderedList>
          </Stack>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </>
  );
};

export const ShowDetailsButton = (props: ButtonProps) => {
  const modal = useModal();

  const showDetails = () => {
    modal.dialog({
      render: close => <DialogDetails onClose={close} />,
      modalProps: { size: "4xl" }
    });
  };

  return (
    <Button
      variant="link"
      onClick={showDetails}
      _focus={{ outline: "none" }}
      {...props}
    >
      Details
    </Button>
  );
};
