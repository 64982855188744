import { generatePath, useParams } from "react-router-dom";

function createRoute<T extends Record<string, string | undefined> = {}>(
  route: string
) {
  return {
    route,
    getPath: (params: T) => generatePath(route, params),
    useParams: () => useParams<T>(),
    usePath() {
      // @ts-ignore
      return this.getPath(this.useParams());
    }
  };
}

// Full-page image viewer switcher (redirect to viewer v1 or v2)
export const VIEWER_PAGE_SWITCHER = createRoute<{
  imageId: string;
  caseId: string;
}>("/cases/:caseId/images/:imageId");
// Viewer v2
export const VIEWER_PAGE = createRoute<{
  imageId: string;
  caseId: string;
}>("/cases/:caseId/view/:imageId");
// Viewer v1
export const IMAGE_VIEWER_PAGE = createRoute<{
  imageId: string;
  caseId: string;
}>("/cases/:caseId/viewer/:imageId");

// Case Item pages
export const CASE_DETAILS_PAGE = createRoute<{ caseId: string }>(
  "/cases/:caseId"
);
export const CASE_EDIT_DETAILS_PAGE = createRoute<{ caseId: string }>(
  "/cases/:caseId/edit"
);
export const CASE_LIST_IMAGES_PAGE = createRoute<{ caseId: string }>(
  "/cases/:caseId/images"
);
export const CASE_ATTACHMENTS_PAGE = createRoute<{ caseId: string }>(
  "/cases/:caseId/attachments"
);
export const CASE_COMMENTS_PAGE = createRoute<{ caseId: string }>(
  "/cases/:caseId/comments"
);
export const CASE_PERMISSIONS_PAGE = createRoute<{ caseId: string }>(
  "/cases/:caseId/permissions"
);
