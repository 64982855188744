import { Query } from "models/query";

export class CaseQuery extends Query {
  static schema = [
    { field: "organizationId", type: "string" },
    { field: "caseNumber", type: "string" },
    { field: "organ", type: "string" },
    { field: "specimenType", type: "string" },
    { field: "diagnosis", type: "string" },
    { field: "grossFindings", type: "string" },
    { field: "microscopicFindings", type: "string" },
    { field: "comments", type: "string" },
    { field: "tags", type: "array" },
    { field: "displayName", type: "string" },
    { field: "organ", type: "string" },
    { field: "modelName", type: "string" },
    { field: "specimenType", type: "string" }
  ];
}

export class ImageQuery extends Query {
  static schema = [
    { field: "displayName", type: "string" },
    { field: "organ", type: "string" },
    { field: "modelName", type: "string" },
    { field: "specimenType", type: "string" }
  ];
}
