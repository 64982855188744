import debug from "debug";
import { Location } from "history";
import { useEffect, useRef } from "react";
import { useLocation, matchPath, RouteProps } from "react-router-dom";
import { createContainer } from "unstated-next";

export function useDebug(label: string) {
  // TODO Could attach this to global context
  return debug(`medmain:${label}`);
}

// The default location when user firstly access the app, after redirection
const DEFAULT_LOCATION = {
  pathname: "/cases",
  search: "",
  hash: "",
  state: null
};

/**
 * Return current location if it is for a page,
 *  or previous stored page location if current location is for a global modal.
 * @param modals Route configs for global modals, for detecting whether current location is for a global modal.
 */
function usePageLocation(modals?: RouteProps[]) {
  // Previous non-modal page location
  const ref = useRef<Location>(DEFAULT_LOCATION);
  const location = useLocation();
  const isModal = (modals || []).some(({ path }) =>
    matchPath(location.pathname, { path })
  );
  useEffect(() => {
    if (!isModal) {
      ref.current = location;
    }
  });
  return {
    pageLocation: isModal ? ref.current : location,
    isModal
  };
}

export const PageLocationContainer = createContainer(usePageLocation);
