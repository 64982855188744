import React, { forwardRef } from "react";
import {
  Input as ChakraInput,
  Textarea as ChakraTextarea,
  Radio as ChakraRadio,
  RadioProps,
  RadioGroup as ChakraRadioGroup
} from "@chakra-ui/react";
import get from "lodash/get";
import styled from "@emotion/styled";

const inputCommonStyles = props => `
  &[aria-invalid="true"] {
    box-shadow: none;
    border-color: ${props.theme.colors.red["400"]};
  }
  &:focus {
    box-shadow: ${props.theme.shadows.outline};
    border-color: ${get(
      props.theme.colors,
      props.isInvalid ? "red.400" : "primary.400"
    )};
  }
  &:hover {
    border-color: ${get(
      props.theme.colors,
      props.isInvalid ? "red.500" : "primary.500"
    )};
  }
`;

// Hard to achieve following logic with combination of _hover _focus _invalid
// Either [aria-invalid="true"] or props.isInvalid could be used to identify invalid
export const Input = styled(ChakraInput)`
  ${props => inputCommonStyles(props)}
`;

export const Textarea = styled(ChakraTextarea)`
  ${props => inputCommonStyles(props)}
`;

export const Radio = forwardRef(
  (props: RadioProps, ref: React.Ref<HTMLInputElement>) => {
    return <ChakraRadio ref={ref} {...props} colorScheme="primary" />;
  }
);

export const RadioGroup = styled(ChakraRadioGroup)`
  span[data-checked]::before {
    width: 6px;
    height: 6px;
  }
`;
