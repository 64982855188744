import React from "react";
import { Select as AntdSelect } from "antd";
import { SelectProps as AntdSelectProps, SelectValue } from "antd/es/select";
import get from "lodash/get";
import styled from "@emotion/styled";

import { CheckIcon, CloseIcon } from "./icons";

type ExtraStyleProps = {
  isInvalid?: boolean;
  isDisabled?: boolean;
};

const Wrapper = styled.div<ExtraStyleProps>`
  .ant-select {
    display: block;
    font-size: 1rem;
    .anticon-close svg {
      display: block;
    }
    .ant-select-selector {
      border-color: ${props =>
        props.isInvalid
          ? props.theme.colors.red["400"]
          : props.theme.colors.gray["200"]};
    }
    &.ant-select-focused .ant-select-selector {
      box-shadow: none;
      border-color: ${props =>
        get(props.theme.colors, props.isInvalid ? "red.400" : "primary.400")};
    }
    &.ant-select .ant-select-selector:hover {
      border-color: ${props =>
        get(props.theme.colors, props.isInvalid ? "red.500" : "primary.500")};
    }
    &.ant-select-disabled {
      opacity: 0.4;
      &.ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
        color: inherit;
      }
    }
  }
  .ant-select-multiple
    .ant-select-selection-overflow-item
    .ant-select-selection-search {
    -webkit-margin-start: 0;
    margin-inline-start: 0;
  }
`;

export type SelectProps<T> = AntdSelectProps<T> & ExtraStyleProps;

export function Select<T extends SelectValue = SelectValue>({
  isInvalid,
  isDisabled,
  ...props
}: SelectProps<T>) {
  return (
    <Wrapper isInvalid={isInvalid}>
      <AntdSelect<T>
        clearIcon={<CloseIcon display="block" />}
        disabled={isDisabled}
        menuItemSelectedIcon={<CheckIcon />}
        getPopupContainer={getPopupContainer}
        {...props}
      />
    </Wrapper>
  );
}
Select.Option = AntdSelect.Option;

// Fix z-index conflicts when the `<Select>` is included in a Chakra modal
function getPopupContainer() {
  const modals = Array.from(document.querySelectorAll("[aria-modal='true']")); // includes modals and drawer components
  const lastModal = modals[modals.length - 1];
  return lastModal ? (lastModal as HTMLElement) : document.body;
}
