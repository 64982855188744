import { useAPI } from "api";
import React, { useEffect, useState } from "react";
import { isMedmainOrg } from "features/ai-legal-terms/agreement";
import { useLocale } from "app/locale";
import { useAccount, useOrgLookup } from "app/auth-container";
import { Select } from "components/core/select";

type TransferableOrgPickerProps = {
  setSelectedOrgId: (orgId: any) => void;
  selectedOrgId: string | undefined;
};
export const TransferableOrgPicker = ({
  setSelectedOrgId,
  selectedOrgId
}: TransferableOrgPickerProps) => {
  const locale = useLocale();
  const { getOrgById } = useOrgLookup();

  const { currentOrgId } = useAccount();
  const api = useAPI();
  const [fetchedOrgs, setFetchedOrgs] = useState<Medmain.Organization[]>([]);
  const fetchOrgs = async () => {
    const {
      data
    }: { data: Medmain.Organization[] } = await api.orgs.findTransferable(
      isMedmainOrg(currentOrgId) ? "all" : currentOrgId
    );
    setFetchedOrgs(data);
  };
  useEffect(() => {
    // Hold on until user authenticated
    if (!currentOrgId) return;
    (async () => {
      try {
        await fetchOrgs();
      } catch (error) {
        console.error(`Unable to fetch the orgs`);
      }
    })();
    // eslint-disable-next-line
    }, [currentOrgId]);
  return (
    <Select
      value={selectedOrgId ? getOrgById(selectedOrgId).name : undefined}
      showSearch={fetchedOrgs.length > 5}
      placeholder={locale.todo("Pick a group")}
      filterOption={filterOption}
      onChange={(value: string) => {
        setSelectedOrgId(value);
      }}
    >
      {fetchedOrgs.map(fetchedOrg => (
        <Select.Option
          key={fetchedOrg.id}
          value={fetchedOrg.id}
          label={fetchedOrg.name}
        >
          {fetchedOrg.name}
        </Select.Option>
      ))}
    </Select>
  );
};

function filterOption(input: string, option): boolean {
  return option.label.toLowerCase().includes(input.toLowerCase());
}
