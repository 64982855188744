import { EffectCallback, useCallback, useRef } from "react";
import { Flex, AlertProps } from "@chakra-ui/react";
import { load } from "@loaders.gl/core";
import { ImageLoader } from "@loaders.gl/images";
import { Position } from "@nebula.gl/edit-modes";
import fromPairs from "lodash/fromPairs";
import keyBy from "lodash/keyBy";
import { IAutorunOptions, autorun } from "mobx";
import { useEffectOnce } from "react-use";
import tinykeys, { KeyBindingMap } from "tinykeys";
import styled from "@emotion/styled";

import { useAuth } from "app/auth-container";
import { useAPI } from "api";
import aiLabels from "data/ai-labels.json";

/**
 * Round number x to precision
 * @param x
 * @param precision
 */
export function round(x: number, precision: number) {
  var y = +x + (precision === undefined ? 0.5 : precision / 2);
  return y - (y % (precision === undefined ? 1 : +precision));
}

// For DeckGL layerFilter props. Limit the layer to be rendered inside targeted views
export const layerFilter = ({ layer, viewport }) =>
  layer.id.startsWith(`${viewport.id}-`);

/**
 * Convert color to WebGL color
 * @param color [r, g, b, a] ranging from [0, 255]
 *
 * TODO:
 * - There should already be utils in deck.gl
 * - Support hex color
 */
export function getColor(color: number[]) {
  return color.map(x => x / 255);
}

// TODO: there should be such helper in libs
export function getDistance(c1: Position, c2: Position) {
  const [x1, y1] = c1;
  const [x2, y2] = c2;
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

export function getMiddlePosition(c1: Position, c2: Position): Position {
  return [(c1[0] + c2[0]) / 2, (c1[1] + c2[1]) / 2];
}

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function getSignificand(x: number) {
  return x * Math.pow(10, Math.ceil(-Math.log10(x)));
}

export function roundSignificand(x: number, decimalPlaces: number = 3) {
  const exponent = -Math.ceil(-Math.log10(x));
  const power = decimalPlaces - exponent;
  const significand = x * Math.pow(10, power);
  // To avoid rounding problems, always work with integers
  if (power < 0) {
    return Math.round(significand) * Math.pow(10, -power);
  }
  return Math.round(significand) / Math.pow(10, power);
}

export function normalizeScaleSize(value: number, minSize: number) {
  const significand = getSignificand(value);
  const minSizeSign = getSignificand(minSize);
  let result = getSignificand(significand / minSizeSign);
  if (result >= 5) {
    result /= 5;
  }
  if (result >= 4) {
    result /= 4;
  }
  if (result >= 2) {
    result /= 2;
  }
  return result;
}

const units: [number, string][] = [
  [1, "cm"],
  [10, "mm"],
  [Math.pow(10, 4), "µm"],
  [Math.pow(10, 7), "nm"]
];

/**
 * Convert value in cm to most compact notion with proper unit
 * @param val value in cm
 *
 * For example, for val = 0.004 (cm), return 40 µm.
 */
export const getWithUnit = (val: number) => {
  let notion = "";
  for (const [multiple, unit] of units) {
    const scaled = roundSignificand(val, 1) * multiple;
    notion = `${scaled} ${unit}`;
    if (scaled >= 1) return notion;
  }
  // Return value in the smallest unit otherwise
  return notion;
};

/**
 * Load image by worker
 *
 * @param url image url
 * @param options options to pass to load
 */
export function loadImage(
  url: string,
  options?: object
): Promise<HTMLImageElement | ImageBitmap> {
  return load(url, ImageLoader, options);
}

/**
 * Return ImageLoader for loading images from server with auth
 */
export function useImageLoader() {
  const { getTokenSilently } = useAuth();
  const api = useAPI();

  return useCallback(
    async (url: string) => {
      const accessToken = await getTokenSilently();
      const headers = api.images.getViewerRequestHeaders({ accessToken });
      return loadImage(url, { headers });
    },
    [getTokenSilently]
  );
}

export const PositionCenter = styled(Flex)`
  height: 100%;
  align-items: center;
  justify-content: center;
`;

// Use a primitive way similar as browser check instead of feature check to detect whether an integrated GPU is used.
//  Hence whether devicePixelRatio could be applied.
// TODO:
// - Better way of detection
// - Allow user to adjust
// - Auto-adaptive to performance metrics
export const canUseDevicePixel = function() {
  const canvas = document.createElement("canvas");
  try {
    const gl = canvas.getContext("webgl");
    if (!gl) return false;
    const info = gl.getExtension("WEBGL_debug_renderer_info");
    if (!info) return false;
    const vendor = gl.getParameter(info.UNMASKED_VENDOR_WEBGL);
    return !/^intel/i.test(String(vendor));
  } catch (e) {
    return false;
  }
};

export const EMPTY_FEATURE_COLLECTION = {
  type: "FeatureCollection",
  features: []
};

export function getCanvasFromCanvasImageSource(
  src: CanvasImageSource | void,
  sx: number,
  sy: number,
  sw: number,
  sh: number,
  dx: number,
  dy: number,
  dw: number,
  dh: number
) {
  if (!src) return;
  const dst = document.createElement("canvas");
  dst.width = dw;
  dst.height = dh;
  const ctx = dst.getContext("2d");
  if (!ctx) return;
  ctx.drawImage(src, sx, sy, sw, sh, dx, dy, dw, dh);
  return dst;
}

export function copyCanvas(src: HTMLCanvasElement | void) {
  if (!src) return;
  const dst = document.createElement("canvas");
  dst.width = src.width;
  dst.height = src.height;
  const ctx = dst.getContext("2d");
  if (!ctx) return;
  ctx.drawImage(src, 0, 0);
  return dst;
}

/**
 * Ultimately, if you're using DOM measurements in the code you're firing after the React callbacks you'll probably want to use this method.
 * Ref https://stackoverflow.com/a/34999925
 */
export function onNextFrame(callback: () => void) {
  setTimeout(function() {
    requestAnimationFrame(callback);
  });
}

/**
 * Shortcut for useEffect(() => autorun(() => view, opts), [])
 * @param view autorun(view, ...)
 * @param opts autorun(..., opts)
 */
export function useAutorun(view: EffectCallback, opts?: IAutorunOptions) {
  const cleanupRef = useRef(() => {});
  useEffectOnce(() => {
    const dispose = autorun(() => {
      const cleanup = view();
      if (cleanup) cleanupRef.current = cleanup;
    }, opts);
    return () => {
      dispose();
      cleanupRef.current();
    };
  });
}

/**
 * Get scale of fitting the box of width and height, into outer box of outerWidth and outerHeight
 */
export function getFitScale(
  outerWidth: number,
  outerHeight: number,
  width: number,
  height: number,
  rotationInDegree: number = 0
) {
  const rotation = (rotationInDegree * Math.PI) / 180;
  const a = Math.abs(Math.cos(rotation));
  const b = Math.abs(Math.sin(rotation));
  const rotatedWidth = width * a + height * b;
  const rotatedHeight = width * b + height * a;
  const scale = Math.min(
    outerWidth / rotatedWidth,
    outerHeight / rotatedHeight
  );
  const scaledWidth = width * scale;
  const scaledHeight = height * scale;
  return {
    scale,
    width: scaledWidth,
    height: scaledHeight,
    ratioW: outerWidth / scaledWidth,
    ratioH: outerHeight / scaledHeight,
    rotation
  };
}

// For merging xstate > statenode > meta
export function mergeMeta(
  meta: Record<string, { message?: string; status?: AlertProps["status"] }>
): { message?: string; status?: AlertProps["status"] } {
  return Object.keys(meta)
    .sort()
    .reduce((acc, key) => {
      const value = meta[key];
      Object.assign(acc, value);
      return acc;
    }, {});
}

export const aiLabelsMap = keyBy(aiLabels, "name");

const tinykeysHandlerWrapper = (handler: (event: KeyboardEvent) => void) => (
  event: KeyboardEvent
) => {
  const target = event.target as HTMLElement;
  // Skip handler if conditions match
  if (
    target?.tagName === "INPUT" ||
    target?.tagName === "TEXTAREA" ||
    target?.isContentEditable
  )
    return;
  handler(event);
};

export function tinykeysWrapper(
  target: Window | HTMLElement,
  keyBindingMap: KeyBindingMap
) {
  return tinykeys(
    target,
    fromPairs(
      Object.keys(keyBindingMap).map(key => [
        key,
        tinykeysHandlerWrapper(keyBindingMap[key])
      ])
    )
  );
}

type DrawTextOption = {
  ctx: CanvasRenderingContext2D;
  text: string;
  font?: string;
  fontSize?: number;
  x?: number;
  y?: number;
  minWidth?: number;
  color?: string;
  bg?: string;
  px?: number;
  py?: number;
  fromBottom?: boolean;
};

export function drawText({
  ctx,
  text,
  font = "sans-serif",
  fontSize = 32,
  x = 0,
  y = 0,
  minWidth = 0,
  color = "#000",
  bg = "rgba(255,255,255,0.25)",
  px = 0,
  py = 0,
  fromBottom = true
}: DrawTextOption) {
  ctx.save();
  ctx.font = `${fontSize}px ${font}`;
  ctx.fillStyle = bg;
  const textWidth = ctx.measureText(text).width;
  const width = Math.max(minWidth, textWidth) + 2 * px;
  const height = fontSize + 2 * py;
  const dx = (width - textWidth) / 2;
  const y0 = fromBottom ? y - height : y;
  ctx.fillRect(x, y0, width, height);
  ctx.fillStyle = color;
  ctx.textBaseline = "top";
  ctx.fillText(text, x + dx, y0 + py);
  ctx.restore();
  return { x, y: y0, width, height };
}

type DrawScaleAndMagnificationOption = {
  ctx: CanvasRenderingContext2D;
  scale: { text: string; size: number } | null;
  magnification: string;
  x0: number;
  y0: number;
  showScale?: boolean;
  showMagnification?: boolean;
  delta?: number;
  px?: number;
  py?: number;
  fontSize?: number;
};

export function drawScaleAndMagnification({
  ctx,
  scale,
  magnification,
  x0,
  y0,
  fontSize = 32,
  showScale = true,
  showMagnification = true,
  delta = 10,
  px = 10,
  py = 10
}: DrawScaleAndMagnificationOption) {
  let x = x0 + delta;
  let y = y0 - delta;
  if (showScale && scale) {
    const bbox = drawText({
      ctx,
      text: scale.text,
      x,
      y,
      minWidth: scale.size,
      px,
      py,
      fontSize
    });
    const scaleEdgeHeight = bbox.height / 3;
    ctx.beginPath();
    ctx.moveTo(x + 1, y - scaleEdgeHeight);
    ctx.lineTo(x + 1, y);
    ctx.moveTo(x + 1, y - scaleEdgeHeight / 2);
    ctx.lineTo(x - 1 + bbox.width, y - scaleEdgeHeight / 2);
    ctx.moveTo(x - 1 + bbox.width, y);
    ctx.lineTo(x - 1 + bbox.width, y - scaleEdgeHeight);
    ctx.stroke();
    y = bbox.y - delta;
  }

  if (showMagnification) {
    drawText({ ctx, text: magnification, x, y, px, py, fontSize });
  }
}

export function getBoundingBox(vertices: number[][]) {
  const xs = vertices.map(([x]) => x);
  const ys = vertices.map(([_, y]) => y);
  const minX = Math.min(...xs);
  const minY = Math.min(...ys);
  const maxX = Math.max(...xs);
  const maxY = Math.max(...ys);
  return [
    [minX, minY],
    [maxX, minY],
    [maxX, maxY],
    [minX, maxY]
  ];
}

export function makeRect(width: number, height: number) {
  return [
    [0, 0],
    [width, 0],
    [width, height],
    [0, height],
    [0, 0]
  ];
}
