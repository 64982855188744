import { useState } from "react";
import {
  Box,
  BoxProps,
  ButtonGroup,
  Grid,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
import { MdCloudUpload } from "react-icons/md";

import { useLocale } from "app/locale";
import { ImageEditableData } from "app/upload-cases-images-container";
import { Button } from "components/core";
import { AddedFileList } from "components/images/image-file-list";
import {
  OrganPicker,
  SpecimenTypePicker,
  ModelPicker
} from "components/common/fields";
import { NumberOf } from "components/batch-import/batch-import-utils";

export const DialogAddImages = ({
  close,
  files,
  rejectedFiles,
  caseNumber,
  canCreatePrediction
}: {
  close: (data?: ImageEditableData) => void;
  files: File[];
  rejectedFiles: File[];
  caseNumber: Medmain.Case["caseNumber"];
  canCreatePrediction: boolean;
}) => {
  const locale = useLocale();
  const [imageData, setImageData] = useState<ImageEditableData>({});

  const onSubmit = event => {
    event.preventDefault();
    close(imageData);
  };
  return (
    <>
      <ModalHeader>
        {locale.caseUpload.modalHeading}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <form id="addImages" onSubmit={onSubmit}>
          <Box mb={4} borderWidth="1px" p={4} borderRadius="4px">
            <Box color="gray.500" fontSize="sm">
              {locale.caseFields.caseNumber}
            </Box>
            <Box fontSize="lg">{caseNumber}</Box>
          </Box>
          <Box p={4} borderWidth="1px" borderRadius="4px" mb={4}>
            {rejectedFiles.length > 0 && (
              <Alert status="warning" mb={4}>
                <AlertIcon />
                <Box>
                  <SupportedImageFileTypes />
                  <Box>
                    Unsupported: <NumberOf items={rejectedFiles} name="file" />
                  </Box>
                </Box>
              </Alert>
            )}
            <AddedFileList files={files} rejected={rejectedFiles} />
          </Box>
          <ImageDataForm
            values={imageData}
            onChange={(key, value) =>
              setImageData(values => ({ ...values, [key]: value }))
            }
            canCreatePrediction={canCreatePrediction}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={() => close()}>{locale.cancelButtonLabel}</Button>
          <Button
            primary
            type="submit"
            form="addImages"
            rightIcon={<MdCloudUpload size="24px" />}
          >
            {locale.caseUpload.uploadButtonLabel(files.length)}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

export const SupportedImageFileTypes = (props: BoxProps) => {
  return (
    <Box {...props}>
      Only <strong>jpeg</strong>, <strong>png</strong>, <strong>tiff</strong>,{" "}
      <strong>svs</strong> or <strong>ndpi</strong> files are supported.
    </Box>
  );
};

type ImageDataFormProps = {
  values: ImageEditableData;
  onChange: (key: string, value: any) => void;
  canCreatePrediction: boolean;
} & Omit<BoxProps, "onChange">;
export const ImageDataForm = ({
  values,
  onChange,
  canCreatePrediction,
  ...props
}: ImageDataFormProps) => {
  const locale = useLocale();

  return (
    <Box p={4} mt={4} borderWidth="1px" borderRadius="4px" {...props}>
      <Grid
        templateColumns={{ base: "100%", md: "220px 1fr" }}
        alignItems="center"
        gap={4}
      >
        <Box fontWeight={500}>{locale.caseFields.specimenType.label}</Box>
        <Box>
          <SpecimenTypePicker
            value={values.specimenType}
            onChange={specimenType => onChange("specimenType", specimenType)}
          />
        </Box>
        <Box fontWeight={500}>{locale.caseFields.organ}</Box>
        <Box>
          <OrganPicker
            value={values.organ}
            onChange={organ => onChange("organ", organ)}
          />
        </Box>
        {canCreatePrediction && (
          <>
            <Box fontWeight={500}>{locale.caseFields.model}</Box>
            <Box>
              <ModelPicker
                value={values.modelName}
                onChange={modelName => onChange("modelName", modelName)}
                currentOrgan={values.organ}
                isEnum
              />
            </Box>
          </>
        )}
      </Grid>
    </Box>
  );
};
