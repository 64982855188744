import React, { SVGProps } from "react";
import { Icon, IconProps, createIcon } from "@chakra-ui/react";
import { AiOutlineDownload } from "react-icons/ai";
import { IoMdShareAlt } from "react-icons/io";
import {
  MdAdd,
  MdArrowBack,
  MdArrowForward,
  MdCheck,
  MdClose,
  MdDelete,
  MdEdit,
  MdHelpOutline,
  MdInfo,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdLoop,
  MdOpenInNew,
  MdRemove,
  MdSearch,
  MdUndo,
  MdWarning
} from "react-icons/md";
// import { RiFileUploadLine } from "react-icons/ri";
import { BsGear } from "react-icons/bs";
import { FaChevronDown, FaChevronUp, FaTrash } from "react-icons/fa";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import {
  RiFileUploadLine,
  RiFolderUploadLine,
  RiFolderSharedLine
} from "react-icons/ri";
import { GenIcon } from "react-icons";

const wrap = (Component: React.FC, settings?: IconProps) => (
  props: IconProps
) => <Icon as={Component} {...settings} {...props} />;

export const AddIcon = createIcon({
  d:
    "M0,12a1.5,1.5,0,0,0,1.5,1.5h8.75a.25.25,0,0,1,.25.25V22.5a1.5,1.5,0,0,0,3,0V13.75a.25.25,0,0,1,.25-.25H22.5a1.5,1.5,0,0,0,0-3H13.75a.25.25,0,0,1-.25-.25V1.5a1.5,1.5,0,0,0-3,0v8.75a.25.25,0,0,1-.25.25H1.5A1.5,1.5,0,0,0,0,12Z",
  displayName: "AddIcon"
});

export const BackIcon = wrap(MdArrowBack, { fontSize: 24 });

export const CheckIcon = wrap(MdCheck, { fontSize: 24 });

export const ChevronDownIcon = wrap(MdKeyboardArrowDown);

export const ChevronRightIcon = wrap(MdKeyboardArrowRight);

export const FaChevronDownIcon = wrap(FaChevronDown);

export const FaChevronUpIcon = wrap(FaChevronUp);

export const CloseIcon = wrap(MdClose);

export const DeleteIcon = wrap(MdDelete, { fontSize: 20 });

export const DownloadIcon = wrap(AiOutlineDownload, { fontSize: 20 });

export const EditIcon = wrap(MdEdit);

export const GearIcon = wrap(BsGear, { fontSize: 20 });

export const ModifyDrawingIcon = wrap(TablerTransformPointBottomLeft, {
  fontSize: 20
});

export const TrashIcon = wrap(FaTrash, { fontSize: 15 });

export const InfoIcon = wrap(MdInfo, { fontSize: 24 });

export const MinusIcon = wrap(MdRemove, { fontSize: 12 });

export const NextIcon = wrap(MdArrowForward, { fontSize: 20 });

export const PreviousIcon = wrap(MdArrowBack, { fontSize: 20 });

export const HelpIcon = wrap(MdHelpOutline, { fontSize: 20 });

export const OpenInNewIcon = wrap(MdOpenInNew, { fontSize: 20 });

export const PlusIcon = wrap(MdAdd, { fontSize: 12 });

export const ReloadIcon = wrap(MdLoop, { fontSize: 20 });

export const ResetIcon = wrap(MdUndo, { fontSize: 20 });

export const SearchIcon = wrap(MdSearch, { fontSize: 20 });

export const UploadIcon = wrap(RiFileUploadLine);

export const CaseTransferIcon = wrap(RiFolderSharedLine, { fontSize: 20 });

export const WarningIcon = wrap(MdWarning, { fontSize: 24 });

export const AngleIcon = createIcon({
  viewBox: "0 0 24 24",
  defaultProps: {
    fill: "none",
    stroke: "currentcolor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round"
  },
  path: [
    <polyline key="polyline" points="20 19 1 19 15 5" />,
    <path key="path" d="M 11.5 19A12 12 0 0 0 9 12" />
  ],
  displayName: "AngleIcon"
});

export function PiArrowFatLeftThin(props) {
  return GenIcon({
    tag: "svg",
    attr: { viewBox: "0 0 256 256", fill: "currentColor" },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M208,76H124V32a4,4,0,0,0-6.83-2.83l-96,96a4,4,0,0,0,0,5.66l96,96A4,4,0,0,0,124,224V180h84a12,12,0,0,0,12-12V88A12,12,0,0,0,208,76Zm4,92a4,4,0,0,1-4,4H120a4,4,0,0,0-4,4v38.34L29.66,128,116,41.66V80a4,4,0,0,0,4,4h88a4,4,0,0,1,4,4Z"
        },
        child: []
      }
    ]
  })(props);
}
export function PiArrowLeftThin(props) {
  return GenIcon({
    tag: "svg",
    attr: { viewBox: "0 0 256 256", fill: "currentColor" },
    child: [
      {
        tag: "path",
        attr: {
          d:
            "M220,128a4,4,0,0,1-4,4H49.66l65.17,65.17a4,4,0,0,1-5.66,5.66l-72-72a4,4,0,0,1,0-5.66l72-72a4,4,0,0,1,5.66,5.66L49.66,124H216A4,4,0,0,1,220,128Z"
        },
        child: []
      }
    ]
  })(props);
}

export function TablerTransformPointBottomLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M3 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1z"></path>
        <path
          fill="currentColor"
          d="M3 18a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1z"
        ></path>
        <path d="M17 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm0 14a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zM11 5h2m-8 6v2m14-2v2m-8 6h2"></path>
      </g>
    </svg>
  );
}

export const ShareIcon = wrap(IoMdShareAlt, { fontSize: 20 });

export const CaseListIcon = wrap(AiOutlineFileSearch, { fontSize: 30 });

export const UploadFilesIcon = wrap(RiFolderUploadLine, { fontSize: 30 });

export const UserListIcon = wrap(FiUsers, { fontSize: 30 });

export const ImportCSVIcon = wrap(RiFileUploadLine, { fontSize: 30 });
