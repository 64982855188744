import React, { useEffect, useState } from "react";
import {
  Box,
  BoxProps,
  ButtonGroup,
  Center,
  Flex,
  Icon,
  Link,
  HStack,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tag,
  TagLabel,
  TagRightIcon,
  Tooltip,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import { GoLinkExternal } from "react-icons/go";
import groupBy from "lodash/groupBy";

import { useLocale } from "app/locale";
import { Button, HelpIcon, Radio, RadioGroup } from "components/core";
import { PredictionAIWarning } from "features/ai-legal-terms/prediction-warning";
import { AIIcon } from "components/image-viewer/plugins/icons";
import { usePreferences } from "app/preferences";
import modelData from "data/ai-prediction-models.json";
import glossary from "data/ai-glossary.json";

export type PredictionModel = {
  modelName: string;
  modelNameEnum: string;
  displayName: string;
  organ: string;
  specimens: string[];
  classification: string[];
  linkURL?: string;
  status: "available" | "coming-soon";
};

type Props = {
  imageId?: string; // TODO remove it if we don't use the API to retrieve the available models
  defaultOrgan?: string | null; // TODO remove it if we don't use image properties to get the default model
  onClose: (value?: string) => void;
};
export const ModelPickerDialog = ({
  imageId,
  defaultOrgan,
  onClose
}: Props) => {
  const locale = useLocale();
  const models = modelData as PredictionModel[];
  const { setPreference } = usePreferences();
  const defaultModelName = useDefaultModelName(models);
  const [modelName, setModelName] = useState<string | undefined>(
    () => defaultModelName
  );

  useEffect(() => {
    if (modelName) {
      setPreference("predictionModel", modelName);
    }
  }, [modelName]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ModalHeader position="relative" borderBottomWidth="0px">
        {locale.aiModelChooserHeading}
        <ModalCloseButton />
      </ModalHeader>
      <PredictionAIWarning />
      <ModalBody pt={6}>
        <form
          id="modelPicker"
          onSubmit={event => {
            event.preventDefault();
            onClose(modelName);
          }}
        >
          <ModelPicker
            models={models}
            value={modelName}
            onChange={setModelName}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button type="button" onClick={() => onClose(undefined)}>
            {locale.cancelButtonLabel}
          </Button>
          <Button
            type="submit"
            form="modelPicker"
            primary
            isDisabled={!modelName}
            leftIcon={<AIIcon size={24} />}
          >
            {locale.aiModelChooserChooseButtonLabel}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

// Return the last model from local preferences,
// taking into account the fact that the model may be not available anymore
function useDefaultModelName(models: PredictionModel[]) {
  const { getPreference } = usePreferences();
  const lastModelUsed = getPreference("predictionModel");
  if (!lastModelUsed) return;
  if (!models.find(model => model.modelName === lastModelUsed)) return;
  return lastModelUsed;
}

type PickerProps = {
  models: PredictionModel[];
  value?: string;
  onChange: (value: string) => void;
};
const ModelPicker = ({ models, value, onChange }: PickerProps) => {
  const modelsByOrgan = groupBy(models, "organ");
  const organs = Object.keys(modelsByOrgan);

  const currentModel = models.find(model => model.modelName === value);
  const currentOrgan = currentModel?.organ;
  const defaultIndex = currentOrgan ? organs.indexOf(currentOrgan) : 0;

  return (
    <Box minH={450}>
      <Step value={1} mb={2}>
        Pick an organ
      </Step>
      <Tabs
        variant="soft-rounded"
        colorScheme="primary"
        onChange={() => {
          // Reset model to force user pick a model when the tab changes
          onChange("");
        }}
        defaultIndex={defaultIndex}
      >
        <TabList flexWrap="wrap">
          {organs.map(organ => (
            <Tab key={organ} borderWidth="1px" mr={2} mb={2}>
              {organ}
            </Tab>
          ))}
        </TabList>
        <Step value={2} mt={4} mb={2}>
          Pick a model
        </Step>
        <TabPanels>
          {organs.map(organ => {
            const models = modelsByOrgan[organ];
            return (
              <TabPanel key={organ} p={0}>
                <Box borderBottomWidth="1px">
                  <ModelList
                    models={models}
                    onChange={onChange}
                    value={value}
                  />
                </Box>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

const Step = ({ value, children, ...props }: { value: number } & BoxProps) => (
  <HStack spacing={2} {...props}>
    <Box fontWeight="bold" color="blue.300" fontSize="2xl">
      {value}
    </Box>
    <Box fontWeight="bold">{children}</Box>
  </HStack>
);

const ModelList = ({
  models,
  value,
  onChange
}: {
  models: PredictionModel[];
  value?: string;
  onChange: (value: string) => void;
}) => {
  return (
    <RadioGroup value={value} onChange={onChange}>
      {models.map((item, index) => {
        const { modelName, linkURL, status } = item;
        const isActive = modelName === value;
        const isDisabled = status !== "available";
        return (
          <Flex
            key={modelName}
            bg={isActive ? "primary.50" : undefined}
            borderTopWidth="1px"
          >
            <Box
              flexGrow={1}
              px={2}
              borderLeftWidth="3px"
              borderColor={isActive ? "primary.500" : "transparent"}
            >
              <Radio
                value={modelName}
                w="100%"
                isDisabled={isDisabled}
                pl={2}
                py={3}
              >
                <ModelItem model={item} />
              </Radio>
            </Box>
            <Center w="100px">
              {linkURL && (
                <Link href={linkURL} isExternal>
                  Reference <Icon as={GoLinkExternal} mx="2px" />
                </Link>
              )}
              {status === "coming-soon" && (
                <Box color="pink.500">Coming soon</Box>
              )}
            </Center>
          </Flex>
        );
      })}
    </RadioGroup>
  );
};

const SpecimenTypeBadge = ({ label }: { label: string }) => {
  return (
    <Tag variant="solid">
      <TagLabel fontSize="14px" textTransform="capitalize">
        {label}
      </TagLabel>
    </Tag>
  );
};

const ClassificationBadge = ({ label }: { label: string }) => {
  const explanation = (glossary as Record<string, string>)[label];
  return explanation ? (
    <Tooltip
      label={explanation}
      aria-label={explanation}
      placement="bottom-start"
      openDelay={200}
      hasArrow
      fontSize="14px"
    >
      <Tag variant="outline">
        <TagLabel fontSize="14px">{label}</TagLabel>
        <TagRightIcon
          as={HelpIcon}
          fontSize="16px"
          mt="-2px"
          color="gray.400"
        />
      </Tag>
    </Tooltip>
  ) : (
    <Tag variant="outline">
      <TagLabel fontSize="14px">{label}</TagLabel>
    </Tag>
  );
};

export const ModelItem = ({ model }: { model: PredictionModel }) => (
  <VStack mb={1.5} alignItems="flex-start" spacing={2}>
    <Box fontSize="md">{model.displayName}</Box>
    {model.classification.length > 0 && (
      <Wrap>
        {model.classification.map(label => (
          <WrapItem key={label}>
            <ClassificationBadge label={label} />
          </WrapItem>
        ))}
      </Wrap>
    )}
    <Wrap>
      {model.specimens.map(specimen => (
        <WrapItem key={specimen}>
          <SpecimenTypeBadge label={specimen} />
        </WrapItem>
      ))}
    </Wrap>
  </VStack>
);
