import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Stack
} from "@chakra-ui/react";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useState } from "react";
import { useLocale } from "app/locale";
import {
  FaChevronDownIcon,
  FaChevronUpIcon,
  Select,
  useModal
} from "components/core";
import { SortIcon } from "components/icons/sort-icon";
import { Field } from "utils/form";

type Props = {
  sorts: Medmain.Sort[];
  setSorts: (arr: Medmain.Sort[]) => void;
  sortType: "caseSort" | "groupSort";
  close: () => void;
};

export const SortButton = ({
  sorts,
  setSorts,
  sortType
}: {
  sorts: Medmain.Sort[];
  setSorts: (arr: Medmain.Sort[]) => void;
  sortType: "caseSort" | "groupSort";
}) => {
  const modal = useModal();
  const showSortDialog = async () => {
    await modal.dialog({
      render: close => (
        <DialogSortList
          sorts={sorts}
          setSorts={setSorts}
          sortType={sortType}
          close={close}
        />
      ),
      modalProps: {
        size: "md",
        isCentered: true
      }
    });
  };

  return (
    <IconButton
      aria-label="Sort"
      variant="outline"
      icon={<SortIcon />}
      onClick={showSortDialog}
    />
  );
};

const DialogSortList = ({ sorts, setSorts, sortType, close }: Props) => {
  const locale = useLocale();

  const priorityOptionValues = {
    caseSort: "displayName",
    groupSort: "name"
  };
  const emptyOption = { value: "", label: "" };
  const priorityOptions: DefaultOptionType[] = [
    {
      value: priorityOptionValues[sortType],
      label: locale[sortType].options.filename
    },
    {
      value: "createdAt",
      label: locale[sortType].options.createdAt
    }
  ];

  const [sortBy, setSortBy] = useState([
    { ...sorts[0] },
    {
      field: (sorts[1]?.field ?? "") as string,
      order: sorts[1]?.order ?? "ASC"
    }
  ]);

  const priorities = [
    {
      name: "priority1st",
      label: "Priority 1st",
      priority: sortBy[0].field,
      options: priorityOptions.filter(
        option => option.value !== sortBy[1]?.field
      ),
      changePriority: (value: string) =>
        setSortBy([
          {
            ...sortBy[0],
            field: value
          },
          sortBy[1]
        ]),
      order: sortBy[0].order,
      changeOrder: (value: string) =>
        setSortBy([
          {
            ...sortBy[0],
            order: value as Medmain.Sort["order"]
          },
          sortBy[1]
        ])
    },
    {
      name: "priority2nd",
      label: "Priority 2nd",
      priority: sortBy[1]?.field ?? "",
      options: [emptyOption, ...priorityOptions].filter(
        option => option.value !== sortBy[0].field
      ),
      changePriority: (value: string) =>
        setSortBy([
          sortBy[0],
          {
            ...sortBy[1],
            field: value
          }
        ]),
      order: sortBy[1]?.order ?? "ASC",
      changeOrder: (value: string) =>
        setSortBy([
          sortBy[0],
          {
            ...sortBy[1],
            order: value as Medmain.CaseSort["order"]
          }
        ])
    }
  ];

  return (
    <>
      <ModalHeader>
        {locale.todo("Sort")}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Stack spacing={4}>
          {priorities.map((priority, index) => (
            <Field
              key={`priority${index + 1}`}
              name={priority.name}
              label={locale.todo(priority.label)}
              errors={[]}
            >
              <HStack>
                <Box flex={4}>
                  <Select
                    value={priority.priority}
                    options={priority.options}
                    onChange={priority.changePriority}
                    filterOption={(input, option) =>
                      option ? option.props.label.indexOf(input) >= 0 : false
                    }
                    showSearch
                  />
                </Box>
                <ButtonGroup size="md" isAttached variant="outline">
                  <IconButton
                    aria-label={`ASC${index + 1}`}
                    mr="-px"
                    icon={
                      <FaChevronUpIcon
                        fontSize="xl"
                        color={
                          priority.priority && priority.order === "ASC"
                            ? "primary.500"
                            : undefined
                        }
                      />
                    }
                    bg={
                      priority.priority && priority.order === "ASC"
                        ? "primary.50"
                        : undefined
                    }
                    onClick={() => priority.changeOrder("ASC")}
                    isDisabled={priority.priority?.length === 0}
                  />
                  <IconButton
                    aria-label={`DESC${index + 1}`}
                    icon={
                      <FaChevronDownIcon
                        fontSize="xl"
                        color={
                          priority.priority && priority.order === "DESC"
                            ? "primary.500"
                            : undefined
                        }
                      />
                    }
                    bg={
                      priority.priority && priority.order === "DESC"
                        ? "primary.50"
                        : undefined
                    }
                    onClick={() => priority.changeOrder("DESC")}
                    isDisabled={priority.priority?.length === 0}
                  />
                </ButtonGroup>
              </HStack>
            </Field>
          ))}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            colorScheme="primary"
            variant="solid"
            onClick={() => {
              setSorts(
                sortBy
                  .filter(item => !!item.field)
                  .map(item => item as Medmain.Sort)
              );
              close();
            }}
          >
            {locale.todo("Sort")}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};
