import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  showLeftSidebar: "768px",
  lg: "1024px",
  xl: "1280px",
  xl2: "1400px",
  xl3: "1600px"
});

export const customTheme = extendTheme({
  breakpoints,
  colors: {
    gray: {
      50: "#f9f9f9",
      100: "#f2f2f2",
      200: "#e8e8e8",
      250: "#dddddd",
      300: "#d5d5d5",
      400: "#aeaeae",
      500: "#808080",
      600: "#555555"
    },
    primary: {
      // colors created from https://coolors.co/8e338e-e1eff6-97d2fb-83bcff-80ffe8
      50: "#f4ecf4",
      100: "#ead9ea",
      200: "#b77db7",
      300: "#a258a2",
      400: "#984598",
      500: "#8e338e", // our primary color
      600: "#752a75",
      700: "#5b215b",
      800: "#411841",
      900: "#270e27"
    },
    blue: {
      900: "#111a39" // a specific color token "invertBgColor" would be better?
    },
    switchOnColor: {
      200: "#8e338e" // comment area switch
    },
    // Tokens migrated from Radium Starter TODO adjust the ChakraUI palette
    altTextColor: "rgba(0, 0, 0, 0.6)",
    backgroundColor: "#ffffff",
    dividerColor: "#cccccc",
    inverseAltTextColor: "rgba(255, 255, 255, 0.8)",
    inverseBoxBackgroundColor: "rgba(17, 26, 57, 0.7)", // transparent bg color applied to buttons and panels
    inverseBoxBorderColor: "rgba(255, 255, 255, 0.88)",
    inverseTextColor: "rgb(255, 255, 255)",
    inverseAccentColor: "#f983e3", // light pink, good contrast on dark background
    grayBackgroundColor: "#f5f8fb",
    statusBackgroundColor: "#f5f5f5",
    headerBackgroundColor: "#f3f7fa",
    mutedTextColor: "rgba(0, 0, 0, 0.38)",
    secondaryButtonColor: "#700A70",
    secondaryTextColor: "rgba(0, 0, 0, 0.6)"
  },
  fontWeights: {
    semibold: 500,
    // Migrated from Radium Starter
    strongFontWeight: "600"
  },
  shadows: {
    outline: "0 0 0 2px rgba(142, 51, 142, 0.4)"
  }
});

declare module "@emotion/react" {
  type CustomTheme = typeof customTheme;

  export interface Theme extends CustomTheme {}
}
