import React from "react";
import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  LinkProps,
  useBreakpoint
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { BiHelpCircle } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";
import { useLocale } from "app/locale";
import { Logo } from "./sidebar";
import { UserMenu } from "./user-menu";
import { NotificationPopover } from "./notification-popover";

export const Header = ({ onClose, onToggle }) => {
  let location = useLocation();
  const screenSize = useBreakpoint() ?? "";

  React.useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <Flex
      as="header"
      w="100%"
      alignItems="center"
      justifyContent={{ base: "space-between", lg: "flex-end" }}
      borderBottomWidth="1px"
      position="fixed"
      height={{ base: "45px", lg: "60px" }}
      width="100%"
      zIndex="10"
      bg={{ base: "headerBackgroundColor", lg: "white" }}
    >
      {["sm", "md"].includes(screenSize) && <Logo />}

      <HStack mx={6} spacing={{ base: 0, lg: 3 }}>
        <HeadHelpLink />
        <NotificationPopover />

        <UserMenu />
        <IconButton
          display={{ base: "block", lg: "none" }}
          ml={0}
          mx={{ base: 0, lg: 6 }}
          icon={<FiMenu fontSize="28px" />}
          boxSize="40px"
          aria-label="Open the Menu"
          onClick={onToggle}
          variant="ghost"
          color={{ base: "primary.500", lg: "gray.500" }}
          borderRadius="99px"
        />
      </HStack>
    </Flex>
  );
};

export const HeadHelpLink = () => {
  const locale = useLocale();

  return (
    <Flex display={{ base: "none", md: "flex" }} alignItems="center" mr={6}>
      <ExternalLink
        href={process.env.REACT_APP_ZENDESK_HELP_CENTER_URL}
        _hover={{ color: "primary" }}
      >
        <Flex>
          {<Icon as={BiHelpCircle} boxSize={6} mr={1} />}
          {locale.todo("Help")}
        </Flex>
      </ExternalLink>
    </Flex>
  );
};

const ExternalLink = ({
  _hover = { color: "inherit" },
  ...props
}: LinkProps) => {
  return (
    <Link display="block" target="_blank" _hover={_hover} {...props}></Link>
  );
};
