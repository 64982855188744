import React from "react";
import PropTypes from "prop-types";

// Source: https://materialdesignicons.com/
// https://github.com/Templarian/MaterialDesign/blob/master/icons/svg

export class CheckerboardIcon extends React.Component {
  static propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    style: PropTypes.object
  };

  static defaultProps = {
    size: 32,
    color: "currentColor"
  };

  render() {
    const { size, color, style } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill={color}
        viewBox="0 0 24 24"
        style={style}
      >
        <path
          fill={color}
          d="M2 2V22H22V2H2M20 12H16V16H20V20H16V16H12V20H8V16H4V12H8V8H4V4H8V8H12V4H16V8H20V12M16 8V12H12V8H16M12 12V16H8V12H12Z"
        />
      </svg>
    );
  }
}
