import { useLocation } from "react-router-dom";
import { stringify } from "qs";
import isEmpty from "lodash/isEmpty";

import { SearchOptions, SortOrderDirection, SearchQueryItem } from "types";
import { LocationDescriptorObject } from "history";
import { PAGINATION_MINIMUM_LIMIT } from "components/common/pagination";

// Default search parameters, used if not specified by the view
export const globalDefaultSearchParams = {
  query: [],
  order: [], // Example: [{ field: "createdAt", direction: "DESC" }]
  page: 1,
  limit: PAGINATION_MINIMUM_LIMIT
};

export function useSearch(searchOptions?: SearchOptions) {
  const location = useLocation();
  const query = searchOptions?.query || [];
  const order = searchOptions?.order || [];

  function getNextLocation(changes): LocationDescriptorObject {
    const params = { ...searchOptions, ...changes };
    const queryString = formatSearchParamsToQueryString(params);
    const nextLocation = { ...location, search: "?" + queryString };
    return nextLocation;
  }

  const setSearchParams = changes => getNextLocation({ page: 1, ...changes });

  const resetSearchParams = () => getNextLocation({ page: 1, query: [] });

  const setQueryField = (
    field: string,
    value: string | string[],
    operator?: string
  ): LocationDescriptorObject => {
    const nextQuery = updateQueryField(query, { field, value, operator });
    const nextLocation = getNextLocation({ query: nextQuery, page: 1 });
    return nextLocation;
  };

  const toggleDirection = (direction: SortOrderDirection): SortOrderDirection =>
    direction === "ASC" ? "DESC" : "ASC";

  const getQueryField = (fieldName: string): SearchQueryItem | undefined => {
    return query.find(
      ({ field, value }) =>
        field === fieldName &&
        (typeof value === "string" ? value !== "" : value.length > 0)
    );
  };

  const toggleSortOrder = (field: string): LocationDescriptorObject => {
    const currentDirection: SortOrderDirection =
      order.find(({ field: fieldName }) => field === fieldName)?.direction ||
      "ASC";
    const nextDirection = toggleDirection(currentDirection);
    return setSortOrder(field, nextDirection);
  };

  const setSortOrder = (
    field: string,
    direction: SortOrderDirection | null
  ): LocationDescriptorObject => {
    const nextOrder = direction === null ? [] : [{ field, direction }];
    const nextLocation = getNextLocation({ order: nextOrder, page: 1 });
    return nextLocation;
  };

  return {
    getQueryField,
    getNextLocation,
    resetSearchParams,
    setQueryField,
    setSearchParams,
    setSortOrder,
    toggleDirection,
    toggleSortOrder
  };
}

export function formatSearchParamsToQueryString(
  searchOptions: SearchOptions
): string {
  const normalizedSearchOptions = {
    ...globalDefaultSearchParams,
    ...searchOptions
  };
  const { query, order, ...otherSearchParams } = normalizedSearchOptions;
  const queryString = stringify(
    {
      ...otherSearchParams,
      query: formatObject(query),
      order: formatObject(order)
    },
    { encode: true, skipNulls: true }
  );
  return queryString;
}

export function updateQueryField(
  query: SearchQueryItem[],
  {
    field,
    value,
    operator = "is"
  }: Omit<SearchQueryItem, "operator"> & { operator?: string }
): SearchQueryItem[] {
  return query
    .filter(queryItem => queryItem.field !== field)
    .concat({ field, operator, value });
}

function removeEmptyValues(query: SearchQueryItem[]): SearchQueryItem[] {
  const isEmptyArray = value => Array.isArray(value) && value.length === 0;
  const isEmptyString = value => value === "";

  return query.filter(
    ({ value }) => !(isEmptyString(value) || isEmptyArray(value))
  );
}

function serializeQuery(query: SearchQueryItem[]): SearchQueryItem[] {
  return query.map(serializeWildcardSearchQueryField);
}

// When `*` is used "to ignore one or more characters",
// update the request sent to the backend, sending `is-like` operator with `%` placeholder
function serializeWildcardSearchQueryField({
  field,
  operator,
  value
}: SearchQueryItem): SearchQueryItem {
  return typeof value === "string" && value.includes("*")
    ? {
        field,
        operator: "is-like",
        value: serializePlaceholder(value)
      }
    : { field, operator, value };
}

function serializePlaceholder(queryValue: string) {
  let updatedValue = queryValue;
  updatedValue = updatedValue.replace(/%/g, "\\%");
  updatedValue = updatedValue.replace(/\*/g, "%");
  return updatedValue;
}

// JSON object => string to be used in the URL
function formatObject(queryObject): string | null {
  const normalizedQueryObject = removeEmptyValues(queryObject);
  return !isEmpty(normalizedQueryObject)
    ? JSON.stringify(normalizedQueryObject)
    : null;
}

// Before sending the Search request to the API, we need to apply some transformations
export function serializeSearchOptions(
  searchOptions: SearchOptions
): SearchOptions {
  const query = serializeQuery(removeEmptyValues(searchOptions.query));

  return {
    ...searchOptions,
    query
  };
}
