import React from "react";
import PropTypes from "prop-types";

// Source: https://thenounproject.com/search/?q=brain%20ai&i=1381707

export class AIIcon extends React.Component {
  static propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    style: PropTypes.object
  };

  static defaultProps = {
    size: 32,
    color: "currentColor"
  };

  render() {
    const { size, color, style } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill={color}
        viewBox="0 0 24 24"
        style={style}
      >
        <path d="M9.754,22.456a.662.662,0,0,1-.633-.477l-.453-1.586H6.329a1.978,1.978,0,0,1-1.974-1.976V16.1l-.686-.226a1.132,1.132,0,0,1-.119-.047A1.3,1.3,0,0,1,2.9,14.123L4.106,11.4a2.859,2.859,0,0,0,.251-1.173,7.983,7.983,0,1,1,14.005,5.238l1.02,3.585a.658.658,0,0,1-.453.812.645.645,0,0,1-.179.025.661.661,0,0,1-.633-.477L17,15.479a.665.665,0,0,1,.158-.636,6.672,6.672,0,1,0-11.492-4.6A4.164,4.164,0,0,1,5.3,11.953L4.086,14.668l1.135.345a.655.655,0,0,1,.45.623v2.792a.663.663,0,0,0,.66.661H9.165a.66.66,0,0,1,.631.475l.59,2.054a.661.661,0,0,1-.452.814A.69.69,0,0,1,9.754,22.456Z" />
        <path d="M12.361,2.389a7.846,7.846,0,0,1,7.8,7.357A7.761,7.761,0,0,1,18.2,15.431l1.041,3.66a.512.512,0,0,1-.353.634.49.49,0,0,1-.141.02.514.514,0,0,1-.494-.373l-1.121-3.933a.516.516,0,0,1,.123-.5,6.81,6.81,0,0,0-4.9-11.526H12.32a6.813,6.813,0,0,0-6.8,6.823,4.038,4.038,0,0,1-.353,1.655l-1.21,2.715,0,.015a.123.123,0,0,0,.089.15l1.137.375a.515.515,0,0,1,.352.487v2.791a.8.8,0,0,0,.8.8H9.165a.514.514,0,0,1,.493.372l.589,2.054a.513.513,0,0,1-.353.635.526.526,0,0,1-.141.019.515.515,0,0,1-.494-.373l-.482-1.69H6.329A1.832,1.832,0,0,1,4.5,18.417V16l-.785-.259c-.035-.012-.07-.025-.1-.041a1.15,1.15,0,0,1-.583-1.518l1.212-2.721A3.028,3.028,0,0,0,4.5,10.228a7.838,7.838,0,0,1,7.837-7.839h.023m0-.288h-.024a8.137,8.137,0,0,0-8.125,8.127,2.711,2.711,0,0,1-.238,1.115L2.763,14.065a1.438,1.438,0,0,0,.73,1.9c.042.019.086.036.13.051l.588.194v2.209a2.124,2.124,0,0,0,2.117,2.12H8.56l.423,1.481a.8.8,0,0,0,.77.582.8.8,0,0,0,.7-.412.794.794,0,0,0,.071-.609l-.59-2.054a.8.8,0,0,0-.769-.58H6.331a.519.519,0,0,1-.516-.518V15.636a.8.8,0,0,0-.548-.759l-.976-.323,1.136-2.543a4.312,4.312,0,0,0,.378-1.773A6.531,6.531,0,1,1,17.05,14.743a.8.8,0,0,0-.192.775l1.121,3.933a.8.8,0,0,0,.77.582.823.823,0,0,0,.22-.031.8.8,0,0,0,.551-.99l-1-3.509A8.125,8.125,0,0,0,12.362,2.1Z" />
        <path d="M12.129,12.986a.657.657,0,0,1-.657-.656V8.887a1.873,1.873,0,1,1,1.314,0V12.33A.657.657,0,0,1,12.129,12.986Zm-.552-6.378a.768.768,0,1,0,.544-.228h0a.767.767,0,0,0-.516.2Z" />
        <path d="M12.129,5.4a1.729,1.729,0,0,1,.513,3.38v3.545a.514.514,0,1,1-1.027,0V8.784a1.729,1.729,0,0,1,.514-3.38m-.008,2.659a.913.913,0,0,0,0-1.826h0a.907.907,0,0,0-.643.267v0a.914.914,0,0,0,.646,1.557m.008-2.947h0a2.017,2.017,0,0,0-.8,3.868v3.345a.8.8,0,1,0,1.6,0V8.984a2.017,2.017,0,0,0-.8-3.868Zm-.416,1.559a.626.626,0,1,1,.409,1.1.627.627,0,0,1-.625-.626.626.626,0,0,1,.182-.44l.034-.034Z" />
        <path d="M10.911,16.907a.658.658,0,0,1-.657-.654V14.362l-1.961-1.1a.661.661,0,0,1-.334-.573V11.76a1.873,1.873,0,1,1,1.314,0v.551l1.961,1.105a.657.657,0,0,1,.334.578V16.25A.658.658,0,0,1,10.911,16.907ZM8.088,9.476a.769.769,0,1,0,.545-.227h0a.76.76,0,0,0-.518.2Z" />
        <path d="M8.617,8.277a1.729,1.729,0,0,1,.513,3.381V12.4l2.033,1.146a.513.513,0,0,1,.261.452V16.25a.514.514,0,0,1-1.027,0V14.279L8.364,13.133a.517.517,0,0,1-.261-.448V11.658a1.73,1.73,0,0,1,.514-3.381m.016,2.655a.914.914,0,0,0,0-1.827h0a.906.906,0,0,0-.643.267v0a.913.913,0,0,0,.646,1.557M8.617,7.99h0a2.017,2.017,0,0,0-.8,3.868v.827a.8.8,0,0,0,.407.7l1.887,1.064v1.8a.8.8,0,0,0,1.6,0V13.993a.8.8,0,0,0-.407-.7L9.418,12.227v-.369a2.017,2.017,0,0,0-.8-3.868ZM8.224,9.544a.7.7,0,1,1-.033.034l.033-.034Z" />
        <path d="M13.347,16.907a.66.66,0,0,1-.658-.654v-2.26a.659.659,0,0,1,.334-.577l1.965-1.1v-.548a1.872,1.872,0,1,1,1.31,0v.924a.66.66,0,0,1-.334.573L14,14.371V16.25A.658.658,0,0,1,13.347,16.907ZM15.1,9.477a.769.769,0,0,0,.545,1.311.77.77,0,0,0,0-1.539h0a.755.755,0,0,0-.517.2Z" />
        <path d="M15.641,8.285a1.729,1.729,0,0,1,.513,3.381v1.027a.512.512,0,0,1-.261.448L13.86,14.287V16.25a.514.514,0,0,1-1.027,0V13.993a.513.513,0,0,1,.261-.452l2.037-1.14v-.735a1.73,1.73,0,0,1,.51-3.381m0,2.647a.914.914,0,0,0,0-1.827h0a.907.907,0,0,0-.643.267v0a.914.914,0,0,0,.646,1.557m0-2.934h0a2.017,2.017,0,0,0-.8,3.868v.367l-1.89,1.057a.8.8,0,0,0-.408.706v2.256a.8.8,0,0,0,1.6,0V14.455l1.887-1.064a.8.8,0,0,0,.407-.7v-.827A2.017,2.017,0,0,0,15.641,8Zm-.409,1.546a.626.626,0,1,1,.409,1.1A.625.625,0,0,1,15.2,9.578l.034-.034Z" />
      </svg>
    );
  }
}
