import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  BoxProps,
  Box
} from "@chakra-ui/react";
import { FormLabelProps } from "@chakra-ui/react";
// TODO Config craco to import ondemand
import camelCase from "lodash/camelCase";
import get from "lodash/get";
import * as yup from "yup";
import { UseFormMethods } from "react-hook-form";

import { useLocale } from "app/locale";
import { Required } from "../components/common/fields";

type FieldProps = {
  name: keyof UseFormMethods["errors"];
  label: string;
  id?: string;
  errors: UseFormMethods["errors"];
  isDisabled?: boolean;
  // Only for form label. Specify in validation for required field
  isRequired?: boolean;
  children: React.ReactElement;
  formLabelProps?: Partial<FormLabelProps>;
  formContentProps?: Partial<BoxProps>;
} & BoxProps;

/**
 * Simple wrapper for Chakra <FormControl>..</FormControl>
 * Usage:
 *
 * type FormData = { fieldName: string }
 * const { register, errors } = useForm<FormData>();
 *
 * <Field name="fieldName" id="fieldId" label="Field Label" errors={errors}>
 *   <Input ref={register} />
 * </Field>
 *
 * TODO
 * - Use forwardRef to support as={Input}, note that forwardRef does not support generic yet.
 * - Add test as document
 */
export function Field(props: FieldProps) {
  const {
    name,
    label,
    id: rawId,
    errors,
    children,
    formLabelProps,
    formContentProps,
    isRequired,
    ...rest
  } = props;
  const id = rawId || camelCase(name as string);
  const isInvalid = !!get(errors, name);
  const message = get(errors, `${name}.message`);
  // Chakra does not export useFormControlContext, thus we need to pass isDisabled explicitly
  //  for input components outside Chakra's scope, such as antd Select.
  // TODO Use useFormControlContext when it is valid
  const propsToChildren = { name, id, isInvalid, isDisabled: rest.isDisabled };
  return (
    <FormControl isInvalid={isInvalid} {...rest}>
      <FormLabel htmlFor={id} {...formLabelProps}>
        {label}
        {isRequired && <Required />}
      </FormLabel>
      {/* TODO Support render props for more than one elements, such as aria-describedby */}
      <Box {...formContentProps}>
        {React.cloneElement(React.Children.only(children), propsToChildren)}
        <FormErrorMessage>{message}</FormErrorMessage>
      </Box>
    </FormControl>
  );
}

/**
 * This hook sets the global locale dictionary of yup, works with useLocale.
 *
 * TODO
 * - Adjust this, depends on i18n policy (whether keep using useLocale)
 * - Test
 */
export function useYupSetLocale() {
  const locale = useLocale();
  yup.setLocale({
    mixed: {
      required: locale.todo("This field is required")
    },
    string: {
      email: locale.todo("This field must be a valid email address"),
      // eslint-disable-next-line
      max: locale.todo("This field must be at most ${max} characters")
    }
  });
}
