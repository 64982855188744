import { useEffect } from "react";
import debugModule from "debug";
import { useLocation } from "react-router-dom";

import { useAccount } from "./auth-container";

const debug = debugModule("medmain:tracking");

export function useTracking() {
  const location = useLocation();
  const { user } = useAccount();
  const userId = user?.sub;
  const pathname = location?.pathname;

  useEffect(() => {
    if (!userId) return;
    setUserId(userId);
  }, [userId]);

  // Track PageView view events only after the User ID has been set
  useEffect(() => {
    if (!userId) return;
    trackPageView(pathname);
  }, [pathname, userId]);
}

export function trackPageView(pathname) {
  if (!window.gtag) return;
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
  debug("PageView event", pathname);
  window.gtag("config", trackingId, { page_path: pathname });
}

export function setUserId(userId) {
  if (!window.gtag) return;
  debug(`UserID: ${userId}`);
  window.gtag("set", { user_id: userId });
}
